import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {HOME_ROUTE} from "../../../Utils";
import {Context} from "../../../index";
import {useNavigate} from "react-router-dom";
import {lang} from "../../../Utils/lang";
import Form from "react-bootstrap/Form";
import {PackParams} from "../../../interface";
import {getNursingActiveCases} from "../../../http/Nursing";

const AccountDetailsNursing = observer(() => {
    const {user, nursingStore, core_store} = useContext(Context);
    const [currentPack, setCurrentPack] = useState<string | null>(null);
    const navigator = useNavigate();

    const handleChangePack = async (e: any) => {
        const pack = e.currentTarget.value;
        if ((pack === 'all' || pack === 'ignore') && user.role !== 'admin') return;
        localStorage.setItem('nursingPackId', pack);
        setCurrentPack(pack);

        const res = await getNursingActiveCases();
        nursingStore.setCases(res);
    }

    useEffect(() => {
        if (!nursingStore.packs) return;

        const nursingPackId = localStorage.getItem('nursingPackId');

        if (nursingPackId === 'all' || nursingPackId === 'ignore') {
            if (user.role === 'admin') {
                setCurrentPack(nursingPackId);
            } else {
                navigator(HOME_ROUTE);
            }
        } else {
            if (!nursingStore.packs.length && user.role !== 'admin') return navigator(HOME_ROUTE);
            const idx = nursingStore.packs.findIndex((el: any) => el._id === nursingPackId);

            if (idx === -1) return navigator(HOME_ROUTE);

            setCurrentPack(nursingPackId);
        }

        // eslint-disable-next-line
    }, [nursingStore.packs]);

    if (!currentPack) return null;

    return (
        <div
            data-category={'accountDetails'}
        >
            <span data-category={'accountDetails'}>{lang.info.core_62[core_store.localization] + ': '}</span>
            <Form.Select
                style={{width: '200px'}}
                onChange={handleChangePack}
                value={currentPack}
                data-category={'accountDetails'}
            >
                {nursingStore.packs.map((el: PackParams) => {
                    return (
                        <option
                            key={el._id}
                            value={el._id}
                            data-category={'accountDetails'}
                        >
                            {el.name}
                        </option>
                    );
                })}
                {user.role === 'admin' &&
                    <>
                        <option
                            value={'all'}
                            data-category={'accountDetails'}
                        >
                            {lang.info.outpatient_94[core_store.localization]}
                        </option>
                        <option
                            value={'ignore'}
                            data-category={'accountDetails'}
                        >
                            {lang.info.outpatient_94_2[core_store.localization]}
                        </option>
                    </>
                }
            </Form.Select>
        </div>
    );
});

export default AccountDetailsNursing;