import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import UserStore from "./stores/UserStore";
import OutpatientStore from "./stores/OutpatientStore";
import CoreStore from "./stores/CoreStore";
import CommonStore from './stores/CommonStore';
import LogStore from "./stores/LogStore";
import CoreStoreSC from "./smartCenter/stores/CoreStore";
import UserStoreSC from "./smartCenter/stores/UserStore";
import AdminStoreSC from "./smartCenter/stores/AdminStore";
import PackStoreSC from "./smartCenter/stores/PackStore";
import NursingStore from "./stores/NursingStore";
import {NursingFlowchart} from "./stores/NursingFlowchart";

export const Context = createContext(null)

ReactDOM.render(
    <React.StrictMode>
        <Context.Provider value={{
            user: new UserStore(),
            ambulant: new OutpatientStore(),
            core_store: new CoreStore(),
            common: new CommonStore(),
            log_store: new LogStore(),
            nursingStore: new NursingStore(),
            nursingFlowchart: new NursingFlowchart(),

            coreStore: new CoreStoreSC(),
            userStore: new UserStoreSC(),
            adminStore: new AdminStoreSC(),
            packStore: new PackStoreSC()
        }}>
            <App/>
        </Context.Provider>
    </React.StrictMode>,
    document.getElementById('app')
);
