import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Modal, Spinner} from "react-bootstrap";
import {lang} from "../../../../Utils/lang";
import {Context} from "../../../../index";
import styles from './ModalLoadingProcess.module.scss';

const ModalLoadingProcess = observer(() => {
    const {core_store} = useContext(Context);

    return (
        <Modal
            contentClassName={styles.modalLoadProcess}
            show={core_store.loading}
            size="sm"
            backdrop={'static'}
            keyboard={false}
            fullscreen={true}
            centered
        >
            <Modal.Body>
                <div className={styles.loadProcess}>
                    <span>{lang.info.core_27[core_store.localization]}</span>
                    <Spinner animation="grow" variant="primary"/>
                </div>
            </Modal.Body>
        </Modal>
    );
});

export default ModalLoadingProcess;