import {observer} from "mobx-react-lite";
import {lang} from "../../../Utils/lang";
import {Button} from "react-bootstrap";
import React, {useContext} from "react";
import {Context} from "../../../index";
import {getCheck} from "../../../http/core";
import {VARIANT_BUTTON} from "../../../Utils";

const ModalTechnical = observer(() => {
    const {core_store} = useContext(Context);

    //закрытие модалки подверждения
    const handleReload = () => {
        //Проверка работает ли сервер
        getCheck().then(r => {
            core_store.setModalTechnical(r);
        }).catch(e => console.log(e));
    }

    return (
        <div
            className={'technicalMaintenance'}
        >
            <strong>{lang.errors.core_10[core_store.localization]}</strong>
            <span>{lang.errors.core_11[core_store.localization]}</span>
            <Button variant={VARIANT_BUTTON} onClick={handleReload}>
                {lang.info.core_46[core_store.localization]}
            </Button>
        </div>
    )
});

export default ModalTechnical;