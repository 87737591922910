import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {getDefaultPackId, HOME_ROUTE} from "../../../Utils";
import {lang} from "../../../Utils/lang";
import Form from "react-bootstrap/Form";
import {PackParams} from "../../../interface";
import {Context} from "../../../index";
import {updateApi} from "../../../http";
import {getActiveCases, getCategory} from "../../../http/Outpatient";
import {useNavigate} from "react-router-dom";

const AccountDetailsOutpatient = observer(() => {
    const {core_store, user, ambulant} = useContext(Context);
    const [currentPack, setCurrentPack] = useState<string | null>(null);
    const [currentStd, setCurrentStd] = useState<string | null>(null);
    const navigator = useNavigate();

    const handleChangeStd = async (e: any) => {
        const std = e.currentTarget.value;
        localStorage.setItem('std', std);
        updateApi(std);
        setCurrentStd(std);

        const defaultPack = getDefaultPackId();
        let packId;
        if (defaultPack && user.curUser.std[std].includes(defaultPack)) {
            packId = defaultPack;
        } else {
            if (!user.curUser.std?.[std]?.length) return navigator(HOME_ROUTE);
            packId = user.curUser.std[std][0];
        }

        if (user.role !== 'admin') {
            for (let i = 0; i < core_store.pack[std].length; ++i) {
                if (core_store.pack[std][i]._id === packId) {
                    core_store.setSelectedPack(core_store.pack[std][i]);

                    break;
                }
            }
        }

        localStorage.setItem('packId', packId);
        setCurrentPack(packId);

        const [category, cases] = await Promise.all([
            getCategory(),
            getActiveCases()
        ]);

        ambulant.resetCaseInfo();
        ambulant.setClasses(category.data);
        ambulant.setSubClasses(cases.data);
    }

    const handleChangePack = async (e: any) => {
        const packId = e.currentTarget.value;
        if ((packId === 'all' || packId === 'ignore') && user.role !== 'admin') return;

        const std = localStorage.getItem('std');
        if (!std || !core_store.pack?.[std]?.length) return;

        if (user.role !== 'admin') {
            for (let i = 0; i < core_store.pack[std].length; ++i) {
                if (core_store.pack[std][i]._id === packId) {
                    core_store.setSelectedPack(core_store.pack[std][i]);

                    break;
                }
            }
        }

        localStorage.setItem('packId', packId);
        setCurrentPack(packId);

        const res = await getActiveCases();
        if (res && res.data) {
            ambulant.setSubClasses(res.data);
            ambulant.resetCaseInfo();
        }
    }

    useEffect(() => {
        if (!core_store.pack) return;

        const packId = localStorage.getItem('packId');
        const std = localStorage.getItem('std');

        if (packId === 'all' || packId === 'ignore') {
            if (user.role === 'admin') {
                setCurrentPack(packId);
                setCurrentStd(std);
            } else {
                navigator(HOME_ROUTE);
            }
        } else {
            if (core_store.pack?.[std]?.length) {
                for (let i = 0; i < core_store.pack[std].length; ++i) {
                    if (core_store.pack[std][i]._id === packId) {
                        setCurrentPack(core_store.pack[std][i]._id);
                        setCurrentStd(std);
                        break;
                    }
                }
            }
        }

        // eslint-disable-next-line
    }, [core_store.pack]);

    return (
        <>
            {currentStd && currentPack
                ?
                <>
                    <div
                        data-category={'accountDetails'}
                    >
                                <span
                                    data-category={'accountDetails'}>{lang.info.core_36[core_store.localization] + ': '}</span>
                        <Form.Select
                            style={{width: '200px'}}
                            data-category={'accountDetails'}
                            onChange={handleChangeStd}
                            value={currentStd}
                        >
                            {Object.keys(user.curUser.std).map((el: string) => {
                                return (
                                    <option
                                        key={'select_std_' + el}
                                        value={el}
                                        data-category={'accountDetails'}
                                    >
                                        {el.toUpperCase()}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </div>
                    <div
                        data-category={'accountDetails'}
                    >
                        <span
                            data-category={'accountDetails'}>{lang.info.core_62[core_store.localization] + ': '}</span>
                        <Form.Select
                            style={{width: '200px'}}
                            onChange={handleChangePack}
                            value={currentPack}
                            data-category={'accountDetails'}
                        >
                            {core_store.pack[localStorage.getItem('std')].map((el: PackParams) => {
                                return (
                                    <option
                                        key={'pack_' + el._id}
                                        value={el._id}
                                        data-category={'accountDetails'}
                                    >
                                        {el.name}
                                    </option>
                                );
                            })}
                            {user.role === 'admin' &&
                                <>
                                    <option
                                        value={'all'}
                                        data-category={'accountDetails'}
                                    >
                                        {lang.info.outpatient_94[core_store.localization]}
                                    </option>
                                    <option
                                        value={'ignore'}
                                        data-category={'accountDetails'}
                                    >
                                        {lang.info.outpatient_94_2[core_store.localization]}
                                    </option>
                                </>
                            }
                        </Form.Select>
                    </div>
                </>
                : <></>
            }
        </>
    );
});

export default AccountDetailsOutpatient;