import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {Button, Form, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {lang} from "../../../Utils/lang";
import {VARIANT_BUTTON} from "../../../Utils";

const ModalAccept = observer(() => {
    const {core_store} = useContext(Context);

    //нажатие на кнопку сохранения
    const checkSave = (flag: boolean) => {
        if (typeof flag === 'boolean' && !flag) {
            return core_store.hideAccept(false);
        }

        if (core_store.modalAcceptParams.headerText.en === lang.info.outpatient_47.en) {
            core_store.hideAccept('category');
        } else if (core_store.modalAcceptParams.headerText.en === lang.info.outpatient_49.en) {
            core_store.hideAccept('case');
        } else if (core_store.modalAcceptParams.validImage) {
            const el: any = document.getElementById('validImageAccept');
            core_store.hideAccept(flag, el.checked);
        } else {
            core_store.hideAccept(flag);
        }
    }

    if (!core_store.showAcceptModal) return <></>;

    return (
        <Modal
            show={core_store.showAcceptModal}
            onHide={core_store.hideAccept}
            size="sm"
            backdrop={'static'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {core_store.modalAcceptParams.headerText[core_store.localization]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {core_store.modalAcceptParams.bodyText[core_store.localization]}
                {core_store.modalAcceptParams.validImage ?
                    <div className={'label-style'}>
                        <hr/>
                        <Form.Label style={{display: 'inline'}}>
                            {lang.info.outpatient_92[core_store.localization]}
                        </Form.Label>
                        <OverlayTrigger
                            placement={'right'}
                            overlay={
                                <Tooltip id={'tooltip'}>
                                    {lang.info.outpatient_93[core_store.localization]}
                                </Tooltip>
                            }
                        >
                            <span className={'icon-style'}>
                                &#11217;
                            </span>
                        </OverlayTrigger>
                        <div className={'select-style'}>
                            <Form.Check id={'validImageAccept'}/>
                            <span>{lang.info.core_56[core_store.localization]}</span>
                        </div>
                    </div>
                    : <></>
                }
            </Modal.Body>
            <Modal.Footer>
                {core_store.modalAcceptParams.secondBtn
                    ? <Button
                        variant={VARIANT_BUTTON}
                        onClick={checkSave.bind(this, false)}
                    >{core_store.modalAcceptParams.secondBtn[core_store.localization]}
                    </Button>
                    : <></>
                }
                <Button
                    variant={VARIANT_BUTTON}
                    onClick={checkSave.bind(this, true)}
                >{core_store.modalAcceptParams.buttonText[core_store.localization]}
                </Button>
                <Button
                    variant={VARIANT_BUTTON}
                    onClick={core_store.modalAcceptParams.buttonText === lang.info.core_46
                        ? core_store.hideAccept.bind(this, false)
                        : core_store.hideAccept}>
                    {
                        core_store.modalAcceptParams.buttonText === lang.info.core_48
                            ? lang.info.core_49[core_store.localization]
                            : lang.info.core_19[core_store.localization]
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default ModalAccept;