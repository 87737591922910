import React, {useContext, useEffect, useRef} from "react";
import {observer} from "mobx-react-lite";
import {Button, Form, Modal} from "react-bootstrap";
import {lang} from "../../../../Utils/lang";
import {Context} from "../../../../index";
import {updateCaseNameEdit, updateCategoryNameEdit} from "../../../../http/Outpatient";
import {DEFAULT_LANGUAGES_VALUES, VARIANT_BUTTON} from "../../../../Utils";
import {updateNameNursingCase} from "../../../../http/Nursing";

interface ModalEditCategoryCaseProps {
    onHide: VoidFunction
    show: boolean
    id: string
    name: string
    flag: boolean
    simulator?: string
}

const ModalEditCategoryCase = observer(({onHide, show, id, name, flag, simulator}: ModalEditCategoryCaseProps) => {
    const {core_store} = useContext(Context);
    const PasswordRef = useRef();

    //сохранение названия категории
    const handleEditCategoryName = async () => {
        // @ts-ignore
        const newName = PasswordRef.current.value.trim();
        if (newName) {
            const res = await updateCategoryNameEdit({
                categoryId: id,
                newName,
                lang: core_store.localization,
                prevData: name
            });

            if (res) {
                return core_store.setErrorParams({
                    errorShow: true,
                    errorTitle: lang.errors.core_7[core_store.localization],
                    errorText: lang.errors.outpatient_18[core_store.localization]
                });
            }
        }

        onHide();
    }

    //сохранение названия кейса
    const handleEditCaseName = async () => {
        // @ts-ignore
        const newName = PasswordRef.current.value.trim();
        if (newName) {
            const res = simulator === 'nursing'
                ? await updateNameNursingCase({caseId: id, value: newName, lang: core_store.localization})
                : await updateCaseNameEdit({caseId: id, newName, lang: core_store.localization, prevData: name});
            if (res) {
                onHide();
                return core_store.setErrorParams({
                    errorShow: true,
                    errorTitle: lang.errors.core_7[core_store.localization],
                    errorText: lang.errors.outpatient_14[core_store.localization]
                });
            }
        }
        onHide();
    }

    //переадресация на изменение категории/кейса
    const handleEdit = () => {
        return flag ? handleEditCaseName()
            : handleEditCategoryName();
    }

    //ставит курсор в конец
    const handleFocusInTheEndString = (e: any) => {
        e.currentTarget.selectionStart = e.currentTarget.value.length;
    }

    //при вводе текста держит высоту
    const handleTextareaChange = () => {
        // @ts-ignore
        PasswordRef.current.style.height = "1px";
        // @ts-ignore
        PasswordRef.current.style.height = (7 + PasswordRef.current.scrollHeight) + "px";
    }


    useEffect(() => {
        if (show) {
            // @ts-ignore
            PasswordRef.current.style.height = "1px";
            // @ts-ignore
            PasswordRef.current.style.height = (PasswordRef.current.scrollHeight) + "px";
        }
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="sm"
            centered
            fullscreen={core_store.tabMode || core_store.mobileMode}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {lang.info.outpatient_66[core_store.localization]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    autoFocus
                    onFocus={handleFocusInTheEndString}
                    type="text"
                    as={'textarea'}
                    id={"textarea-change-name"}
                    placeholder={DEFAULT_LANGUAGES_VALUES[core_store.localization]}
                    defaultValue={name}
                    onChange={handleTextareaChange}
                    ref={PasswordRef}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={VARIANT_BUTTON}
                    onClick={handleEdit}
                >
                    {lang.info.core_1[core_store.localization]}
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default ModalEditCategoryCase;