import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {API_URl} from '../Utils';

//создание подключения к бэку
const $api = axios.create({
    baseURL: API_URl,
    responseType: 'json'
});

//создание подключения для авторизации
const $authApi = axios.create({
    baseURL: API_URl,
    responseType: 'json'
})

const updateApi = (std: string) => {
    $api.defaults.headers.common.db = std;
}

const updateWSID = (id: string) => {
    $api.defaults.headers.common.ws = id;
}

const authInterceptor = (config: AxiosRequestConfig) => {
    config.headers.authorization = `Medvr ${localStorage.getItem('token')}`;
    return config;
}

let logout: () => void;

export const logoutInterceptor = (callback: () => void) => {
    logout = callback;
}

$api.interceptors.request.use(authInterceptor);


let isRefreshing = false;
let refreshSubscribers: any = [];

$api.interceptors.response.use((config: AxiosResponse) => {
    return config;
}, async (error) => {
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        error.config._isRetry = true;
        if (!isRefreshing) {
            isRefreshing = true;
            try {
                const response = await axios.get(`${API_URl}api/user/refresh`, {
                    timeout: 5000,
                    headers: {
                        project: 'CM',
                        refresh: localStorage.getItem('refreshToken')
                    }
                });
                localStorage.setItem('token', response.data.accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);

                for (const callback of refreshSubscribers) {
                    callback();
                }

                refreshSubscribers = [];
                isRefreshing = false;

                const newConfig = {
                    ...error.config,
                    headers: {
                        ...error.config.headers,
                    }
                };
                return $api.request(newConfig);
            } catch (e) {
                console.log('The user is not logged in.');

                logout?.();
            }
        } else {
            return new Promise(resolve => {
                const newConfig = {
                    ...error.config,
                    headers: {
                        ...error.config.headers,
                    }
                };

                refreshSubscribers.push(() => {
                    resolve($api.request(newConfig));
                });
            });
        }
    }

    throw error;
});

export {
    $api,
    $authApi,
    updateApi,
    updateWSID
};