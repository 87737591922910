import React, {useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import styles from './AccountDetails.module.scss';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import {lang} from '../../../Utils/lang';
import {
    AMB_PRIEM_ROUTE,
    HOME_ROUTE, NURSING_ROUTE,
    VARIANT_BUTTON
} from '../../../Utils';
import {useLocation, useNavigate} from "react-router-dom";
import {logout} from "../../../http/user";
import AccountDetailsOutpatient from "./AccountDetailsOutpatient";
import AccountDetailsNursing from "./AccountDetailsNursing";

const AccountDetails = observer(() => {
    const {core_store, user} = useContext(Context);
    const [showAccountDetails, setShowAccountDetails] = useState(false);
    const detailsRef = useRef<any>();
    const navigator = useNavigate();
    const location = useLocation();

    let listLanguages;
    if (user.auth && user.role === 'admin') {
        listLanguages = core_store.listLanguages;
    } else if (user.auth && location.pathname.indexOf(AMB_PRIEM_ROUTE) !== -1) {
        listLanguages = user.curUser.languages;
    } else if (user.auth && location.pathname.indexOf(NURSING_ROUTE) !== -1) {
        listLanguages = user.curUser.nursingLanguages;
    } else {
        listLanguages = core_store.listLanguages;
    }

    const handleClickClose = (event: any) => {
        if (event.target?.dataset?.category !== 'accountDetails') {
            document.removeEventListener('mousedown', handleClickClose);
            setShowAccountDetails(false);
        } else if (event.target?.id === 'btn-logout') {
            document.removeEventListener('mousedown', handleClickClose);
        }
    }

    const handleOpenAccount = (e: any) => {
        const rect = e.currentTarget.getBoundingClientRect();
        detailsRef.current = {
            right: `${document.documentElement.clientWidth - (rect.right - ((rect.right - rect.left) / 2))}px`,
            top: '6vh'
        }

        document.addEventListener('mousedown', handleClickClose);
        setShowAccountDetails(true);
    }

    //изменение языка для приложения
    const handleChangeLanguages = (e: any) => {
        core_store.setLocalization(e.currentTarget.value);
        localStorage.setItem('lang', core_store.localization);
        const url = new URL(document.URL);
        url.searchParams.set('lang', core_store.localization);
        window.history.replaceState({}, '', url);
    }

    const handleLogOut = () => {
        const refreshToken = localStorage.getItem('refreshToken');
        user.logOut();
        core_store.setLanguages();
        logout(refreshToken);
        navigator(HOME_ROUTE);
    }

    useEffect(() => {
        if (!user.auth) return;
        if (user.role === 'admin') return;

        let listLanguages;
        if (location.pathname.indexOf(AMB_PRIEM_ROUTE) !== -1) {
            listLanguages = user.curUser.languages;
        } else if (location.pathname.indexOf(NURSING_ROUTE) !== -1) {
            listLanguages = user.curUser.nursingLanguages;
        } else {
            listLanguages = core_store.listLanguages;
        }

        if (!listLanguages) return;
        if (listLanguages.indexOf(core_store.localization) !== -1) return;

        core_store.setLocalization(listLanguages[0]);
        localStorage.setItem('lang', core_store.localization);
        const url = new URL(document.URL);
        url.searchParams.set('lang', core_store.localization);
        window.history.replaceState({}, '', url);

        // eslint-disable-next-line
    }, [location.pathname]);

    return (
        <>
            <div
                className={styles.divAccount}
                onClick={handleOpenAccount}
                data-category={'accountDetails'}
            >
                <span>{user.email.split('@')[0]}</span>
            </div>
            {showAccountDetails &&
                <div
                    className={styles.accountDetails}
                    style={{right: detailsRef.current.right, top: detailsRef.current.top}}
                    data-category={'accountDetails'}
                >
                    <span
                        className={styles.email}
                        data-category={'accountDetails'}
                        style={user.email.length > 15 ? {fontSize: '.9em'} : null}
                    >
                        {user.email}</span>
                    <div
                        className={styles.langSelect}
                        data-category={'accountDetails'}
                    >
                        <span data-category={'accountDetails'}>{lang.info.core_70[core_store.localization] + ':'}</span>
                        <Form.Select
                            data-category={'accountDetails'}
                            onChange={handleChangeLanguages}
                            value={core_store.localization}
                        >
                            {listLanguages.map((el: string) => {
                                return (
                                    <option
                                        key={'option-lang_' + el}
                                        data-category={'accountDetails'}
                                        value={el}
                                    >
                                        {el}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </div>
                    {window.location.pathname === AMB_PRIEM_ROUTE
                        ? <AccountDetailsOutpatient/>
                        : null
                    }
                    {window.location.pathname === NURSING_ROUTE
                        ? <AccountDetailsNursing/>
                        : null
                    }
                    <div data-category={'accountDetails'}>
                        <Button
                            id={'btn-logout'}
                            variant={VARIANT_BUTTON}
                            onClick={handleLogOut}
                            className={styles.btnExit}
                            data-category={'accountDetails'}
                        >
                            {lang.info.auth_4[core_store.localization]}
                        </Button>
                    </div>
                </div>
            }
        </>
    )
});

export default AccountDetails;