import React, {useContext, useRef} from 'react';
import {Button, FloatingLabel, Form, Modal} from "react-bootstrap";
import {insertCategory} from "../../../../http/Outpatient";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import {lang} from "../../../../Utils/lang";
import {VARIANT_BUTTON} from "../../../../Utils";

const OutpatientCreateClassModal = observer(({onHide, show}: { onHide: VoidFunction, show: boolean }) => {
    const {core_store} = useContext(Context);
    const textRef = useRef<string>('');

    //валидация, сохранение в базу, обновление
    const checkSave = async () => {
        if (textRef.current) {
            core_store.setLoading(true);
            const res = await insertCategory({
                value: textRef.current,
                lang: core_store.localization
            });

            if (res) {
                return core_store.setErrorParams({
                    errorShow: true,
                    errorTitle: lang.errors.core_7[core_store.localization],
                    errorText: lang.errors.outpatient_18[core_store.localization]
                });
            }
            onHide();
        } else {
            core_store.setErrorParams({
                errorShow: true,
                errorTitle: lang.errors.core_7[core_store.localization],
                errorText: lang.errors.core_6[core_store.localization]
            });
        }
    }

    const handleBlur = (e: any) => {
        textRef.current = e.currentTarget.value.trim();
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            fullscreen={core_store.tabMode || core_store.mobileMode}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {lang.info.outpatient_3[core_store.localization]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabel
                    controlId="InputNameClass"
                    label={lang.info.core_6[core_store.localization]}
                    className="mb-3"
                >
                    <Form.Control
                        autoFocus
                        type="text"
                        onBlur={handleBlur}
                    />
                </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={VARIANT_BUTTON}
                    onClick={checkSave}
                >
                    {lang.info.core_1[core_store.localization]}
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default OutpatientCreateClassModal;