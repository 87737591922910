import {makeAutoObservable} from 'mobx';
import {
    AutocompleteParams,
    ErrorInterface,
    IContextMenu,
    ICreateExam,
    IExamList,
    IModalFileUpload,
    IStationsInfo,
    IUsersForExam,
    ModalAcceptParams,
    Result,
    SearchParams,
    Stat
} from "../interface";

export default class CoreStoreSC {
    //История  игры
    history: Result;
    //Все результаты
    results: Array<Result>
    //Параметры поиска
    searchParams: SearchParams = {
        date: null,
        name: '',
        group: '',
        case: '',
        simulator: [],
        other: []
    }
    //обьект с массивом строк для автокомплита
    filterAutocomplete: AutocompleteParams
    //Флаг для активации изм общего списка
    needSearch: boolean = false;
    //Данные статистики
    stat: Stat | null
    //параметры для модалки ошибок(видимость, заголовок, текст)
    errorParams: ErrorInterface = {
        errorShow: false,
        errorTitle: '',
        errorText: ''
    };
    //контекстное меню
    contextMenu: IContextMenu = {
        id: '',
        type: '',
        show: false,
        x: '',
        y: '',
        anyInfo: null
    }
    //список юзеров для выбора при создании экзамена
    usersForExam: IUsersForExam = {
        license: [],
        noLicense: []
    };
    //список экзаменов
    examList: IExamList = {
        scheduled: [],
        passed: [],
    };
    //скроллинг фильтра
    scrollFilter: boolean = false;
    //параметры модалки
    modalAcceptParams: ModalAcceptParams = {
        showAcceptModal: false,
        hideAccept: null,
        headerText: null,
        bodyText: null,
        buttonText: null
    };
    //информация для создания экзамена
    createExamInfo: any | null = null;
    createExam: ICreateExam = {
        caseId: '',
        name: '',
        group: '',
        email: '',
        timer: '00:00'
    };
    //станции
    stationsInfo: Array<IStationsInfo>
    //модалка для загрузки файлов
    modalFileUpload: IModalFileUpload | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setModalFileUpload(data: IModalFileUpload) {
        this.modalFileUpload = data;
    }

    resetCreateExam() {
        this.createExam = {
            caseId: '',
            name: '',
            group: '',
            email: '',
            timer: '00:00'
        }
    }

    setFieldCreateExam(type: string, value: string, email?: string) {
        this.createExam[type] = value;

        if (email) this.createExam.email = email;
    }

    setStationsInfo(data: Array<IStationsInfo>) {
        this.stationsInfo = data;
    }

    setCreateExamInfo(data: any) {
        this.createExamInfo = data;
    }

    setModalAcceptParams(data: ModalAcceptParams | false) {
        if (data) {
            this.modalAcceptParams = data;
        } else {
            this.modalAcceptParams = {
                showAcceptModal: false,
                hideAccept: null,
                headerText: null,
                bodyText: null,
                buttonText: null
            }
        }
    }

    changeScrollFilter() {
        this.scrollFilter = !this.scrollFilter;
    }

    //Установка данных для отображения ошибки
    setErrorParams(data: ErrorInterface) {
        this.errorParams = data;
    }

    //Зактрытие модалки ошибки.
    closeErrorModal() {
        this.errorParams.errorShow = false;
    }

    setHistory(data: any) {
        this.history = data;
    }

    setResults(data: Array<Result>) {
        this.results = data;
    }

    updateResult(data: any) {
        const idx = this.results.findIndex((el: Result) => el._id === data.elemId);
        if (idx !== -1) {
            if (data.type === 'delete') {
                const arr = [...this.results];
                arr.splice(idx, 1);
                this.results = arr;
            } else if (data.type === 'changeName') {
                this.results[idx].name = data.value;
                this.results[idx].table[0].row[0].column[1] = data.value;
            }
        }
    }

    setSearchParam(key: string, value: string | Date) {
        this.searchParams[key] = value;
    }

    changeNeedSearch() {
        this.needSearch = !this.needSearch;
    }

    setFilterAutocomplete(data: AutocompleteParams) {
        if (data.dates.length) {
            for (let i = 0; i < data.dates.length; i++) {
                data.dates[i] = new Date(data.dates[i]);
            }
        }
        this.filterAutocomplete = data;
    }

    setStat(data: Stat | null) {
        this.stat = data;
    }

    getFullDate() {
        let month: string | number = this.searchParams.date.getMonth() + 1;
        let day: string | number = this.searchParams.date.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        return this.searchParams.date.getFullYear() + '-' + month + '-' + day;
    }

    setCheckboxSearchFilter(key: string, data: string, flag: boolean) {
        // @ts-ignore
        let arr = [...this.searchParams[key]];
        if (flag) {
            arr.push(data);
        } else {
            arr.splice(arr.indexOf(data), 1);
        }
        this.searchParams[key] = arr;
    }

    resetSearchParams(key?: string) {
        if (key) {
            this.searchParams[key] = [];
        } else {
            this.searchParams = {
                date: null,
                name: '',
                group: '',
                case: '',
                simulator: [],
                other: []
            }
        }
    }

    checkSearchParams() {
        for (const key in this.searchParams) {
            if (key === 'other' || key === 'simulator') {
                if (this.searchParams[key].length) {
                    return false;
                }
            } else if (this.searchParams[key]) {
                return false;
            }
        }
        return true;
    }

    setContextMenu(params: IContextMenu) {
        this.contextMenu = params;
    }

    closeContextMenu() {
        this.contextMenu = {
            id: '',
            type: '',
            show: false,
            x: '',
            y: '',
            anyInfo: null
        }
    }

    setUsersForExam(type: string, data: any) {
        this.usersForExam[type] = data;
    }

    resetUsersForExam() {
        this.usersForExam = {
            license: [],
            noLicense: []
        };
    }

    setExamList(data: IExamList) {
        this.examList = data;
    }
}