import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import AccountDetails from "./AccountDetails.tsx";
import {Context} from "../../../index";
import styles from "../../../smartCenter/companents/UI/Header/Header.module.scss";
import {Link, useLocation} from "react-router-dom";
import {ADMIN_ROUTE, EXAM_ROUTE, LICENSE_ROUTE, RESULT_ROUTE, SMART_CENTER_ROUTE, STATIONS_ROUTE} from "../../../Utils";
import {lang} from "../../../smartCenter/Utils/lang";
import {lang as langCM} from "../../../Utils/lang";

const NavBarMain = observer(() => {
    const {user, core_store} = useContext(Context);
    const location = useLocation();

    const isSmartCenter = location.pathname.indexOf(SMART_CENTER_ROUTE) !== -1;

    if (user.auth) return (
        <>
            {isSmartCenter &&
                <div className={styles.navBarDiv}>
                    {user.role === 'admin' &&
                        <>
                            <Link
                                className={styles.link}
                                to={ADMIN_ROUTE}
                            >
                                {langCM.info.core_33[core_store.localization]}
                            </Link>
                            <Link
                                className={styles.link}
                                to={STATIONS_ROUTE}
                            >
                                {lang.info.core_63[core_store.localization]}
                            </Link>
                        </>

                    }
                    {user.role !== 'admin'
                        ?
                        <>
                            <Link
                                className={styles.link}
                                to={RESULT_ROUTE}
                            >
                                {lang.info.core_23[core_store.localization]}
                            </Link>
                            <Link
                                className={styles.link}
                                to={LICENSE_ROUTE}
                            >
                                {lang.info.core_42[core_store.localization]}
                            </Link>
                            <Link
                                className={styles.link}
                                to={EXAM_ROUTE}
                            >
                                {lang.info.core_45[core_store.localization]}
                            </Link>
                        </>
                        : <></>
                    }
                </div>
            }
            <AccountDetails/>
        </>
    )

    return <></>;
});

export default NavBarMain;