import axios from "axios";
import {lang} from "./lang";

export const PROJECT_NAME = "SC";
//Элементы интерфейса фильтрации
export const FILTER_ELEMENTS: Array<{ [lang: string]: string, key: string }> = [
    {...lang.info.core_94, key: 'date'},
    {...lang.info.core_13, key: 'name'},
    {...lang.info.core_14, key: 'group'},
    {...lang.info.core_15, key: 'simulator'},
    {...lang.info.core_90, key: 'case'},
    {...lang.info.core_95, key: 'other'}
];

//Элементы фильтрации для категории "другое"
export const OTHER_FILTER_ELEMENTS: Array<{ [lang: string]: string }> = [
    {...lang.info.core_42, key: 'license'},
    {...lang.info.core_91, key: 'notlicense'},
    {...lang.info.core_92, key: 'desktop'},
    {...lang.info.core_93, key: 'vr'},
    {...lang.info.core_45, key: 'exam'},
];

//Элементы интерфейса фильтрации
export const CREATE_EXAM_ELEMENTS: Array<{ [lang: string]: string, key: string }> = [
    {...lang.info.core_31, key: 'name'},
    {...lang.info.core_14, key: 'group'},
    {...lang.info.core_90, key: 'case'},
];

//Общий стили для компанентов
export const TEXT_FIELD_VARIANT = 'outlined';
export const BUTTON_VARIANT = 'outline-primary';

//Для проверки на валидность email
export const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

//Список для контекстного меню
export const CONTEXT_MENU_LIST: Record<string, Array<Record<string, string>>> = {
    users: [
        {
            lang: lang.info.core_98,
            action: 'mailing'
        },
        {
            lang: lang.info.core_34,
            action: 'edit'
        },
        {
            lang: lang.info.core_96,
            action: 'blocking'
        },
        {
            lang: lang.info.core_97,
            action: 'unblocking'
        },
        {
            lang: lang.info.core_41,
            action: 'delete'
        }
    ],
    stations: [
        {
            lang: lang.info.core_34,
            action: 'edit'
        },
        {
            lang: lang.info.core_41,
            action: 'delete'
        }
    ],
}

//объект дефолтных значений
export const DEFAULT_EMPTY_LANGUAGES_VALUES: { [key: string]: string } = {
    ru: '',
    en: '',
    tr: '',
    es: '',
    ar: '',
    de: '',
    pt: '',
    fr: '',
    zh: '',
    ko: '',
    ja: '',
    th: '',
    he: '',
    zh_tw: '',
    fil: ''
}

/*
* Определение к какому типу ошибка относиться.
*/
export const detectedError = (e: any) => {
    if (e instanceof ErrorEvent) {
        console.log(e.message);
    } else if (axios.isAxiosError(e)) {
        console.log(e.response.data);
        return e.response.data;
    } else {
        console.log(e);
    }
}


/*
* @param clientX и clientY- координаты указателя
* @param type - тип для контекстного меню
*
* Считает помещается ли контекстное меню, где был произведен клик.
*/
export function countPixel(clientX: number, clientY: number, type: string) {
    let x: number | string, y: number | string;
    if ((clientY + 10 + 40 * CONTEXT_MENU_LIST[type].length) > document.documentElement.clientHeight) {
        y = (clientY - ((clientY + 10 + 40 * CONTEXT_MENU_LIST[type].length) - document.documentElement.clientHeight)).toString() + 'px';
    } else {
        y = (clientY).toString() + 'px';
    }

    if ((clientX + 250) > document.documentElement.clientWidth) {
        x = (clientX - ((clientX + 250) - document.documentElement.clientWidth)).toString() + 'px';
    } else {
        x = (clientX).toString() + 'px';
    }

    return {x, y};
}


/*
* @param str - текст
*
* Перевод в аперкейс первой буквы текста
*/
export function ucFirst(str: string) {
    if (!str) return str;
    str = str.trim();
    if (!str || str.length <= 1) return str;
    return str[0].toUpperCase() + str.slice(1);
}

export function convertDate(ms: number): Record<string, string> {
    const d = new Date(ms);
    const date = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();


    const h = d.getHours().toString().padStart(2, "0");
    const m = d.getMinutes().toString().padStart(2, "0");

    return {
        date: `${date}.${month}.${year}`,
        time: `${h}:${m}`
    }
}