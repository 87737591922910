import React, {useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import styles from "./ModalChooseFileUpload.module.scss";
import {ListFilesEl} from "../../../../interface";
import {Button, CloseButton, Form, Spinner} from "react-bootstrap";
import {lang} from "../../../../Utils/lang";
import {Context} from "../../../../index";
import {deleteFile} from "../../../../http/Outpatient";
import {getInfoFiles, updateFileDesc} from "../../../../http/core";
import {
    API_DIALOGUE_AUDIO_URL,
    API_IMAGES_URL,
    API_INSPECTION_AUDIO_URL,
    API_INSPECTION_IMAGE_URL,
    VARIANT_BUTTON
} from "../../../../Utils";
import {ReactComponent as RefreshIcon} from '../../../../assets/img/refresh_icon.svg';

const FileList = observer(({listFilesRef, urlFileRef, setShowFile}: any) => {
    const {core_store, user} = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(30);
    const [search, setSearch] = useState('');
    const mediaRef = useRef(null);

    const handleReplace = (id: string, url: string) => {
        const closeModalAccept = (flag: boolean) => {
            if (typeof flag === 'boolean' && flag === true) {
                listFilesRef.current = id;
                urlFileRef.current = url;
                setShowFile(true);
            }
            core_store.setShowAcceptModal(false);
        }

        core_store.setModalAcceptParams({
            headerText: lang.info.core_90,
            bodyText: lang.info.core_110,
            buttonText: lang.info.core_49,
        });
        core_store.setHideAccept(closeModalAccept)
        core_store.setShowAcceptModal(true);
    }

    const handleKeyDown = async (e: any) => {
        if (e.keyCode === 13) {//Нажатие Enter
            e.preventDefault();
            e.target.blur();
        } else if (e.keyCode === 27) {//Нажатие ESC
            e.preventDefault();
        }
    }

    const handleUpdateDesc = (id: string, prevValue: string, e: any) => {
        const description = e.currentTarget.textContent.trim();
        if (!description) {
            e.currentTarget.textContent = prevValue;
            return;
        }
        if (description !== prevValue) {
            core_store.setLoading(true);
            updateFileDesc({id, description, collection: core_store.fileParams.collection});
        }
    }

    const handleSearch = (e: any) => {
        setLoading(true);
        setLimit(30);
        setSearch(e.currentTarget.value);
        mediaRef.current.src = '';
    }

    const handleDelete = (id: string) => {
        const closeModalAccept = (flag: boolean) => {
            if (typeof flag === 'boolean' && flag === true) {
                core_store.setLoading(true);
                //удаление
                deleteFile(id, core_store.fileParams.collection);
                setSearch('');
            }
            core_store.setShowAcceptModal(false);
        }

        core_store.setModalAcceptParams({
            headerText: lang.info.core_90,
            bodyText: lang.info.outpatient_61,
            buttonText: lang.info.core_56,
        });
        core_store.setHideAccept(closeModalAccept)
        core_store.setShowAcceptModal(true);
    }

    //выбор сущ. файла
    const handleChange = (id: string) => {
        listFilesRef.current = id;
    }

    const handlePreview = (url: string) => {
        let href = '';
        switch (core_store.fileParams.collection) {
            case 'images':
                href = API_IMAGES_URL + url;
                break;
            case 'inspection_image':
                href = API_INSPECTION_IMAGE_URL + url;
                break;
            case 'inspection_audio':
                href = API_INSPECTION_AUDIO_URL + url;
                break;
            case 'dialogue_audio':
                href = API_DIALOGUE_AUDIO_URL + url;
                break;
        }

        if (core_store.fileParams.type === 'image') {
            core_store.setParamsFullScreen(true, href);
        } else {
            mediaRef.current.src = href;
            mediaRef.current.currentTime = 0.0;
            mediaRef.current.play();
        }
    }

    const scrollHandler = (e: any) => {
        if (e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + window.innerHeight) < 50) {
            setLimit(limit * 2);
        }
    }

    useEffect(() => {
        getInfoFiles({
            collection: core_store.fileParams.collection,
            limit,
            search,
            userId: user.id
        }).then(r => {
            if (r.data) {
                core_store.setListFiles(r.data)
                setLoading(false);
            } else throw new Error(lang.errors.outpatient_15('Files.')[core_store.localization]);
        }).catch(e => core_store.setErrorParams({
            errorShow: true,
            errorTitle: lang.errors.core_19[core_store.localization],
            errorText: e.message
        }));
        // eslint-disable-next-line
    }, [search, limit]);

    return (
        <div>
            <div className={'d-flex justify-content-center'}>
                <audio
                    src={''}
                    hidden
                    ref={mediaRef}
                />
            </div>
            <input
                type={'text'}
                className={styles.customInput}
                placeholder={lang.info.core_25[core_store.localization]}
                onChange={handleSearch}
            />
            <div
                className={styles.fileList}
                onScroll={scrollHandler}
            >
                {loading
                    ? <Spinner animation={'border'} variant={'primary'}/>
                    : core_store.listFiles.map((el: ListFilesEl) => {
                        return (
                            <div
                                className={styles.fileElem}
                                key={el._id}
                            >
                                {user.role === 'admin' || el.userId === user.id ?
                                    <CloseButton
                                        className={'close-button-dark mr-3'}
                                        title={lang.info.outpatient_109[core_store.localization]}
                                        onClick={handleDelete.bind(this, el._id)}
                                    />
                                    : <></>
                                }
                                {user.role === 'admin' || el.userId === user.id ?
                                    <RefreshIcon
                                        className={'refresh-icon mr-3'}
                                        title={lang.info.outpatient_81[core_store.localization]}
                                        onClick={handleReplace.bind(this, el._id, el.url)}
                                    />
                                    : <></>
                                }
                                <Form.Check
                                    onChange={handleChange.bind(this, el._id)}
                                    type={'radio'}
                                    inline
                                    name={'chooser-file'}
                                />
                                <Button
                                    variant={VARIANT_BUTTON}
                                    onClick={handlePreview.bind(this, el.url)}
                                >
                                    {lang.info.core_109[core_store.localization]}
                                </Button>
                                <div
                                    id={el._id}
                                    className={styles.descDiv}
                                    contentEditable={user.role === 'admin' ? true : user.id === el.userId}
                                    suppressContentEditableWarning={true}
                                    onBlur={handleUpdateDesc.bind(this, el._id, el.description)}
                                    onKeyDown={handleKeyDown}
                                >
                                    {el.description}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
});

export default FileList;