import {makeAutoObservable, runInAction} from "mobx";
import {IPacks, ISimulator, IStationLicense, IUserLicense} from "../interface";
import {
    deleteNursingLicense,
    deleteNursingPackLicense,
    deleteStationLicense,
    deleteUserLicense,
    getNursingPackById,
    getNursingStationLicense,
    getNursingUserLicense,
    getPackById,
    getStationLicense,
    getUserLicense,
    insertNursingStationLicense,
    insertNursingUserLicense,
    insertStationLicense,
    insertUserLicense, packLicenseMailing, packNursingLicenseMailing,
    updateNursingLicenseBlocking,
    updateNursingPackAcceptMac,
    updateNursingPackLicenseLimit,
    updateNursingStationLicense,
    updateNursingUserLicense,
    updatePackAcceptMac,
    updatePackLicenseBlocking,
    updatePackLicenseLimit,
    updateStationLicense,
    updateUserLicense
} from "../http/core";

export default class PackStoreSC {
    pack: ISimulator<IPacks> = {
        outpatient: null,
        nursing: null
    };
    userLicense: ISimulator<IUserLicense[]> = {
        outpatient: null,
        nursing: null
    };
    stationLicense: ISimulator<IStationLicense[]> = {
        outpatient: null,
        nursing: null
    };
    licenseEditId: string;
    simulator: 'outpatient' | 'nursing' | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    setSimulator(data: 'outpatient' | 'nursing') {
        this.simulator = data;
    }

    setPack(data: IPacks) {
        this.pack[this.simulator] = data;
    }

    setUserLicense(data: IUserLicense[]) {
        this.userLicense[this.simulator] = data;
    }

    setStationLicense(data: IStationLicense[]) {
        this.stationLicense[this.simulator] = data;
    }

    setLicenseLimit(data: number, type: string) {
        this.pack[this.simulator].licenseLimit[type] = data;
    }

    setLicenseEditId(id: string) {
        this.licenseEditId = id;
    }

    async updatePackLicenseLimit(params: any) {
        let res, data: number;

        if (this.simulator === 'outpatient') {
            res = await updatePackLicenseLimit(params);
            if (res && typeof res.data === 'number') {
                data = res.data;
            }
        } else if (this.simulator === 'nursing') {
            res = await updateNursingPackLicenseLimit(params);
            if (res && typeof res === 'number') {
                data = res;
            }
        }
        runInAction(() => {
            this.pack[this.simulator].licenseLimit[params.type] = data;
        })
    }

    async getPackData(packId: string) {
        if (this.simulator === 'outpatient') {
            const res = await Promise.all([
                getPackById(packId),
                getUserLicense(packId),
                getStationLicense(packId)
            ]);

            runInAction(() => {
                this.pack[this.simulator] = res[0].data;
                this.userLicense[this.simulator] = res[1].data;
                this.stationLicense[this.simulator] = res[2].data;
            })
        } else if (this.simulator === 'nursing') {
            const res = await Promise.all([
                getNursingPackById(packId),
                getNursingUserLicense(packId),
                getNursingStationLicense(packId)
            ]);

            runInAction(() => {
                this.pack[this.simulator] = res[0];
                this.userLicense[this.simulator] = res[1];
                this.stationLicense[this.simulator] = res[2];
            })
        }
    }

    async acceptPackMac({id, flag, type}: { id: string, flag: boolean, type: string }) {
        if (!this.simulator || !this.pack[this.simulator]) return;
        const packId = this.pack[this.simulator]._id;

        if (this.simulator === 'outpatient') {
            if (flag) {
                await updatePackAcceptMac({id});
            } else {
                await deleteStationLicense(id);
            }
        } else if (this.simulator === 'nursing') {
            if (flag) {
                await updateNursingPackAcceptMac({id});
            } else {
                await deleteNursingPackLicense(id, type);
            }
        }

        await this.getStationLicense(packId);
    }

    async updateLicense({value, type}: { value: Record<string, string>, type: string }) {
        if (!this.simulator || !this.pack[this.simulator]) return;
        const packId = this.pack[this.simulator]._id;

        if (this.simulator === 'outpatient') {
            if (type === 'station') {
                const response = await updateStationLicense({packId, value});
                if (response?.error) return response.error;

                await this.getStationLicense(packId);
            } else if (type === 'user') {
                await updateUserLicense({packId, value});

                await this.getUserLicense(packId);
            }
        } else if (this.simulator === 'nursing') {
            if (type === 'station') {
                const response = await updateNursingStationLicense({packId, value});
                if (response?.error) return response.error;

                await this.getStationLicense(packId);
            } else if (type === 'user') {
                await updateNursingUserLicense({packId, value});

                await this.getUserLicense(packId);
            }
        }
    }

    async deleteLicense({type, id}: { type: string, id: string }) {
        if (!this.simulator || !this.pack[this.simulator]) return;
        const packId = this.pack[this.simulator]._id;

        if (this.simulator === 'outpatient') {
            if (type === 'stations') {
                await deleteStationLicense(id);

                await this.getStationLicense(packId);
            } else if (type === 'users') {
                await deleteUserLicense(id);

                await this.getUserLicense(packId);
            }
        } else if (this.simulator === 'nursing') {
            await deleteNursingLicense(id, type);

            if (type === 'stations') {
                await this.getStationLicense(packId);
            } else if (type === 'users') {
                await this.getUserLicense(packId);
            }
        }
    }

    async updateBlockingLicense({blocking, id}: { blocking: boolean, id: string }) {
        if (!this.simulator || !this.pack[this.simulator]) return;
        const packId = this.pack[this.simulator]._id;

        if (this.simulator === 'outpatient') {
            await updatePackLicenseBlocking({id, packId, blocking});

            await this.getUserLicense(packId);
        } else if (this.simulator === 'nursing') {
            await updateNursingLicenseBlocking({id, blocking});

            await this.getUserLicense(packId);
        }
    }

    async insertLicense({
                            type,
                            packId,
                            value,
                            prepare,
                            guid
                        }: { type: string, packId: string, value?: object, guid?: string, prepare: (res: any, flag: boolean) => void }) {
        if (this.simulator === 'outpatient') {
            if (type === 'user') {
                const response = await insertUserLicense({
                    packId,
                    value
                });
                prepare(response, true);

                await this.getUserLicense(packId);
            } else if (type === 'station') {
                const response = await insertStationLicense({
                    packId,
                    guid
                });
                prepare(response, false);

                await this.getStationLicense(packId);
            }
        } else if (this.simulator === 'nursing') {
            if (type === 'user') {
                const response = await insertNursingUserLicense({
                    packId,
                    value
                });
                prepare(response, true);
                await this.getUserLicense(packId);
            } else if (type === 'station') {
                const response = await insertNursingStationLicense({
                    packId,
                    guid
                });
                prepare(response, false);

                await this.getStationLicense(packId);
            }
        }
    }

    async getUserLicense(packId: string) {
        if (this.simulator === 'outpatient') {
            const res = await getUserLicense(packId);
            if (res?.data) {
                runInAction(() => {
                    this.userLicense[this.simulator] = res.data;
                })
            }
        } else if (this.simulator === 'nursing') {
            const res = await getNursingUserLicense(packId);
            if (res) {
                runInAction(() => {
                    this.userLicense[this.simulator] = res;
                });
            }
        }
    }

    async getStationLicense(packId: string) {
        if (this.simulator === 'outpatient') {
            const res = await getStationLicense(packId);
            if (res?.data) {
                runInAction(() => {
                    this.stationLicense[this.simulator] = res.data;
                })
            }
        } else if (this.simulator === 'nursing') {
            const res = await getNursingStationLicense(packId);
            if (res) {
                runInAction(() => {
                    this.stationLicense[this.simulator] = res;
                });
            }
        }
    }

    licenseMailing(id: string) {
        if (!this.simulator || !this.pack[this.simulator]) return;
        const packId = this.pack[this.simulator]._id;

        if (this.simulator === 'outpatient') {
            packLicenseMailing({licenseId: id, packId}).catch(e => console.log(e));
        } else if (this.simulator === 'nursing') {
            packNursingLicenseMailing({licenseId: id, packId}).catch(e => console.log(e));
        }
    }
}