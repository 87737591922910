import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {getPackCaseLeft} from "../../../../../http/Outpatient";
import {lang} from "../../../../../Utils/lang";
import {Context} from "../../../../../index";

const ModalCopyCaseLeft = observer(() => {
    const {core_store} = useContext(Context);
    const [caseLeft, setCaseLeft] = useState<number | null>(null);

    useEffect(() => {
        const packId = localStorage.getItem('packId');
        if (packId) {
            getPackCaseLeft(packId).then(r => {
                if (r.data && r.data > -1) {
                    setCaseLeft(r.data);
                }
            })
        }
    }, []);

    return (
        <>
            {caseLeft !== null &&
                <div className={'mb-3'}>
                    {lang.info.outpatient_113[core_store.localization] + ': ' + caseLeft}
                </div>
            }
        </>
    );
});

export default ModalCopyCaseLeft;