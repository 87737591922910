import {makeAutoObservable} from 'mobx';
import {
    CaseNames,
    DiagnosisDisease,
    IExamCategory,
    IExamTabs, IMedicineCategory,
    Jobs,
    LangLocalization, MedicineObject,
    ObjectIdAndName, Race,
    ReviewObject,
} from "../interface";

export default class CommonStore {
    //Условие для визуального осмотра
    patientCloth: Array<LangLocalization> | null
    //болезни
    disease: Array<DiagnosisDisease> | null
    //осмотр
    percussion: Array<ReviewObject>
    auscultation: Array<ReviewObject>
    palpation: Array<ReviewObject>
    visual: Array<ReviewObject>
    instruments: Array<ReviewObject>
    //обследование
    examCategory: Array<IExamCategory> | null;
    examTabs: Array<IExamTabs> | null
    examActiveTab: string
    //jobs
    jobs: Array<Jobs>
    //treatment
    hospitalization: Array<ObjectIdAndName>
    diet: Array<ObjectIdAndName>
    frequency: Array<ObjectIdAndName>
    duration: Array<ObjectIdAndName>
    injection: Array<ObjectIdAndName>
    condition: Array<ObjectIdAndName>
    form: Array<ObjectIdAndName>
    //препараты
    medicine: Array<MedicineObject>
    medicine_category: Array<IMedicineCategory>
    activeMedicine: MedicineObject
    //имена
    patientName: CaseNames[]
    activePatientName: CaseNames
    nameRace: Race[]

    constructor() {
        makeAutoObservable(this);
    }

    updateCaseNames(data: any) {
        if (data.type === 'insert') {
            this.patientName.push(data.value);
        } else {
            const idx = this.patientName.findIndex((el: CaseNames) => el._id === data.elemId);
            if (idx !== -1) {
                if (data.type === 'delete') {
                    this.patientName.splice(idx, 1);
                    if (this.activePatientName && this.activePatientName._id === data.elemId) {
                        this.activePatientName = null;
                    }
                } else if (data.type === 'name') {
                    this.patientName[idx].name[data.lang] = data.value;
                } else if (data.type === 'male' || data.type === 'race') {
                    // @ts-ignore
                    this.patientName[idx][data.type] = data.value;
                }
            }
        }
    }

    setNameRace(data: Race[]) {
        this.nameRace = data;
    }

    setPatientName(data: CaseNames[]) {
        this.patientName = data;
    }

    setActivePatientName(data: CaseNames) {
        this.activePatientName = data;
    }

    setActiveMedicine(data: MedicineObject) {
        this.activeMedicine = data;
    }

    setMedicine(data: Array<MedicineObject>) {
        this.medicine = data;
    }

    setTreatmentCategory(data: Array<IMedicineCategory>) {
        this.medicine_category = data;
    }

    setHospitalization(data: Array<ObjectIdAndName>) {
        this.hospitalization = data;
    }

    //Установка данных диеты
    setDiet(data: Array<ObjectIdAndName>) {
        this.diet = data;
    }

    //Установка данных кратность приема
    setFrequency(data: Array<ObjectIdAndName>) {
        this.frequency = data;
    }

    //Установка данных продолжительность приема
    setDuration(data: Array<ObjectIdAndName>) {
        this.duration = data;
    }

    //Установка данных продолжительность приема
    setInjection(data: Array<ObjectIdAndName>) {
        this.injection = data;
    }

    //Установка данных особые условия
    setCondition(data: Array<ObjectIdAndName>) {
        this.condition = data;
    }

    //Установка данных форма
    setForm(data: Array<ObjectIdAndName>) {
        this.form = data;
    }

    updateMedicineCategory(data: any) {
        if (this.medicine && this.medicine.length) {
            for (let i = 0; i < this.medicine.length; ++i) {
                if (this.medicine[i]._id === data.medicineId) {
                    this.medicine[i].subCategoryId = data.newCategoryId;
                    break;
                }
            }
        }
    }

    updateMedicineDataCommon(data: any) {
        if (this.medicine) {
            if (data.type === 'insert') {
                this.medicine.push(data.value);
            } else {
                const idx = this.medicine.findIndex((el: MedicineObject) => el._id === data.medicineId);
                if (idx !== -1) {
                    if (data.type === 'delete') {
                        this.medicine.splice(idx, 1);
                    } else if (data.type === 'changeName') {
                        this.medicine[idx].name[data.lang] = data.value;
                    } else if (data.type === 'nameDose') {
                        const idxElem = this.medicine[idx].dose.findIndex((el: any) => el._id === data.elemId);
                        if (idxElem !== -1) {
                            this.medicine[idx].dose[idxElem].name[data.lang] = data.value;
                        }
                    } else if (data.type === 'addDose') {
                        this.medicine[idx].dose.push(data.value);

                        if (data.valid !== undefined) {
                            this.medicine[idx].valid = data.valid;
                        }
                    } else if (data.type === 'deleteDose') {

                        const idxElem = this.medicine[idx].dose.findIndex((el: any) => el._id === data.elemId);
                        if (idxElem !== -1) {
                            this.medicine[idx].dose.splice(idxElem, 1);
                        }

                        if (data.valid !== undefined) {
                            this.medicine[idx].valid = data.valid;
                        }
                    } else {
                        if (data.value === true) {
                            this.medicine[idx][data.type].push(data.elemId);
                        } else {
                            const idxElem = this.medicine[idx][data.type].indexOf(data.elemId);
                            if (idxElem !== -1) {
                                this.medicine[idx][data.type].splice(idxElem, 1);
                            }
                        }

                        if (data.valid !== undefined) {
                            this.medicine[idx].valid = data.valid;
                        }
                    }
                }
            }
        }
    }

    updateTreatmentDataCommon(data: any) {
        // @ts-ignore
        if (this[data.tabName]) {
            if (data.type === 'insert') {
                // @ts-ignore
                this[data.tabName].push(data.value);
            } else {
                // @ts-ignore
                const idx = this[data.tabName].findIndex((el: ObjectIdAndName) => el._id === data.elemId);
                if (idx !== -1) {
                    if (data.type === 'delete') {
                        // @ts-ignore
                        this[data.tabName].splice(idx, 1);
                    } else if (data.type === 'change') {
                        // @ts-ignore
                        this[data.tabName][idx].name[data.lang] = data.value;
                    }
                }
            }
        }
    }

    setInspectionTab({
                         percussion,
                         auscultation,
                         palpation,
                         visual,
                         instruments
                     }: {
        percussion: Array<ReviewObject> | null,
        auscultation: Array<ReviewObject> | null,
        palpation: Array<ReviewObject> | null,
        visual: Array<ReviewObject> | null,
        instruments: Array<ReviewObject> | null
    }) {
        this.percussion = percussion;
        this.auscultation = auscultation;
        this.palpation = palpation;
        this.visual = visual;
        this.instruments = instruments;
    }

    setDiseaseCommon(data: Array<DiagnosisDisease> | null) {
        this.disease = data;
    }

    setJobs(data: Array<Jobs>) {
        this.jobs = data;
    }

    updateJobsCommon(data: any) {
        if (this.jobs) {
            if (data.type === 'insertJob') {
                this.jobs.push(data.value);
            } else {
                const idx = this.jobs.findIndex((el: Jobs) => el._id === data.elemId);
                if (idx !== -1) {
                    if (data.type === 'changeJob') {
                        this.jobs[idx].name[data.lang] = data.value;
                    } else if (data.type === 'deleteJob') {
                        this.jobs.splice(idx, 1);
                    }
                }
            }
        }
    }

    updateDisease({id, lang, value, parentIds}: { id: string, lang: string, value: string, parentIds: string[] }) {
        if (!parentIds) {
            const idx = this.disease.findIndex((el: DiagnosisDisease) => el._id === id);
            if (idx !== -1) {
                this.disease[idx].name[lang] = value;
            }
        } else {
            let countParent = 0;
            const iterDisease = (arr: Array<DiagnosisDisease>) => {
                for (let i = 0; i < arr.length; ++i) {
                    if (countParent === parentIds.length) {
                        if (arr[i]._id === id) {
                            arr[i].name[lang] = value;
                            return;
                        }
                    } else if (arr[i]._id === parentIds[countParent]) {
                        ++countParent;
                        if (arr[i].childNodes) {
                            iterDisease(arr[i].childNodes);
                        }
                        return;
                    }
                }
            }

            iterDisease(this.disease);
        }
    }

    //Установка условия
    setPatientCloth(data: Array<LangLocalization> | null) {
        this.patientCloth = data;
    }

    updateReviewSimulator(data: any) {
        // @ts-ignore
        if (!this[data.tabName]) return;

        // @ts-ignore
        const idx = this[data.tabName].findIndex((el: ReviewObject) => el._id === data.elemId);
        if (idx === -1) return;

        // @ts-ignore
        this[data.tabName][idx][data.type] = data.value;
    }

    updateReview(data: any) {
        // @ts-ignore
        if (this[data.tabName]) {
            if (data.type === 'insert') {
                // @ts-ignore
                this[data.tabName].push(data.value);
            } else {
                // @ts-ignore
                const idx = this[data.tabName].findIndex((el: ReviewObject) => el._id === data.elemId);
                if (idx !== -1) {
                    if (data.type === 'name') {
                        // @ts-ignore
                        this[data.tabName][idx].name[data.lang] = data.value;
                    } else if (data.type === 'delete') {
                        // @ts-ignore
                        this[data.tabName].splice(idx, 1);
                    } else if (data.type === 'cloth') {
                        // @ts-ignore
                        this[data.tabName][idx].patientCloth = data.value;
                    } else if (data.type === 'image/flag') {
                        // @ts-ignore
                        this[data.tabName][idx].image = data.value;
                    }
                }
            }
        }
    }

    setExamCategory(data: Array<IExamCategory> | null) {
        this.examCategory = data;
    }

    setExamTabs(data: Array<IExamTabs> | null) {
        this.examTabs = data;
    }

    setExamActiveTab(data: string) {
        this.examActiveTab = data;
    }

    updateExamData(data: any) {
        if (data.type === 'categoryName' || data.type === 'insertCategory' || data.type === 'deleteCategory') {
            if (this.examCategory) {
                if (data.type === 'insertCategory') {
                    this.examCategory.push({
                        _id: data.elemId,
                        name: data.value
                    });
                } else {
                    const idx = this.examCategory.findIndex((el: IExamCategory) => el._id === data.elemId);
                    if (idx !== -1) {
                        if (data.type === 'categoryName') {
                            this.examCategory[idx].name[data.lang] = data.value;
                        } else if (data.type === 'deleteCategory') {
                            this.examCategory.splice(idx, 1);
                        }
                    }
                }
            }
        } else {
            if (this.examTabs) {
                if (data.type === 'insert') {

                } else {
                    const idx = this.examTabs.findIndex((el: IExamTabs) => el._id === data.elemId);
                    if (idx !== -1) {
                        if (data.type === 'tabName') {
                            this.examTabs[idx].name[data.lang] = data.value;
                        } else if (data.type === 'deleteTab') {
                            this.examTabs.splice(idx, 1);
                        } else if (data.type === 'tabCategory') {
                            this.examTabs[idx].category = data.value;
                        } else if (data.type === 'changeTabType') {
                            this.examTabs[idx].type = data.value;
                        }
                    }
                }
            }
        }
    }
}
