import {makeAutoObservable} from "mobx";
import {IEditUser, UsersInterface} from "../interface";

export default class AdminStoreSC {
    usersList: UsersInterface[] | null = null;
    editUser: IEditUser | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setUsersList(data: UsersInterface[] | null) {
        this.usersList = data;
    }

    setEditUser(data: IEditUser | null) {
        this.editUser = data;
    }

    updatePackIdEditUser(data: string, simulator: string) {
        if (!this.editUser) return;

        if (simulator === 'outpatient') {
            this.editUser.packId = data;
        } else if (simulator === 'nursing') {
            this.editUser.nursingPackId = data;
        }
    }
}