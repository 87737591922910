import React, {useContext, useRef, useState} from 'react';
import {Button, FloatingLabel, Form, Modal} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import {copyCase} from "../../../../http/Outpatient";
import {lang} from "../../../../Utils/lang";
import {Classes} from "../../../../interface";
import {canOpenCategory, useName, VARIANT_BUTTON} from "../../../../Utils";
import ModalCopyCaseLeft from "./ModalCopyCase/ModalCopyCaseLeft";

interface ModalCopyCaseProps {
    parentCaseId: string
    onHide: VoidFunction
    show: boolean
    categoryId: string
}

const ModalCopyCase = observer(({onHide, show, parentCaseId, categoryId}: ModalCopyCaseProps) => {
    const {ambulant, core_store, user} = useContext(Context);
    const [category, setCategory] = useState(categoryId);
    const firstClassRef = useRef<any>();
    const descriptionRef = useRef<any>();

    //валидация, сохрание в базу
    const checkSave = async () => {
        firstClassRef.current.value = firstClassRef.current.value.trim();
        if (firstClassRef.current.value) {
            core_store.setLoading(true);
            const resCopy = await copyCase({
                name: firstClassRef.current.value,
                description: descriptionRef.current.value,
                categoryId: category,
                caseId: parentCaseId,
                packId: localStorage.getItem('packId'),
                author: user.id,
                lang: core_store.localization
            });
            core_store.setLoading(false);

            if (resCopy) {
                if (typeof resCopy === 'string') {
                    core_store.setErrorParams({
                        errorShow: true,
                        errorTitle: lang.errors.core_7[core_store.localization],
                        errorText: resCopy === 'Case already exist'
                            ? lang.errors.outpatient_14[core_store.localization]
                            : lang.errors.core_20[core_store.localization]
                    });
                } else if (resCopy.data) {
                    ambulant.copyCase(resCopy.data);
                    ambulant.setSelectedSubMenuId(resCopy.data._id);
                    const url = new URL(document.URL);
                    url.searchParams.set('caseId', resCopy.data._id);
                    window.history.replaceState({}, '', url);
                }
            }
            onHide();
        } else {
            core_store.setErrorParams({
                errorShow: true,
                errorTitle: lang.errors.core_7[core_store.localization],
                errorText: lang.errors.outpatient_1[core_store.localization]
            });
        }
    }

    const handleChangeCategory = (e: any) => {
        if (e.currentTarget.value !== category) {
            setCategory(e.currentTarget.value);
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                backdrop={'static'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                fullscreen={core_store.tabMode || core_store.mobileMode}
            >
                <Modal.Header
                    closeButton
                >
                    <Modal.Title id="contained-modal-title-vcenter">
                        {lang.info.outpatient_2[core_store.localization]}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalCopyCaseLeft/>
                    <FloatingLabel
                        controlId="InputNameClass"
                        label={lang.info.core_6[core_store.localization]}
                        className="mb-2"
                    >
                        <Form.Control
                            autoFocus
                            type="text"
                            ref={firstClassRef}
                            placeholder={'*'}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="InputDescriptionClass"
                        label={lang.info.core_5[core_store.localization]}
                        className="mb-2"
                    >
                        <Form.Control
                            type="text"
                            ref={descriptionRef}
                            placeholder={'*'}
                        />
                    </FloatingLabel>
                    <span>{lang.info.outpatient_1[core_store.localization]}</span>
                    <div
                        className={'mt-2'}
                    >
                        <FloatingLabel
                            controlId="floatingSelectCategoryId"
                            label={lang.info.core_4[core_store.localization]}
                        >
                            <Form.Select
                                value={category}
                                placeholder={'*'}
                                onChange={handleChangeCategory}
                            >
                                {ambulant.classes.map((el: Classes) => {
                                    if (!canOpenCategory({
                                        pack: core_store.selectedPack,
                                        categoryId: el._id,
                                        role: user.role
                                    })) {
                                        return null;
                                    }

                                    let {
                                        name,
                                        color
                                    } = useName({
                                        el: el.name,
                                        lang: core_store.localization,
                                        listLang: core_store.listLanguages
                                    });

                                    return (
                                        <option
                                            key={'option_' + el._id}
                                            value={el._id}
                                            style={{color}}
                                        >
                                            {name}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={VARIANT_BUTTON}
                            onClick={checkSave}
                    >{lang.info.core_1[core_store.localization]}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});

export default ModalCopyCase;