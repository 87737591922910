import React, {useContext} from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {
    HOME_ROUTE,
    AMB_PRIEM_ROUTE,
    LOG_ROUTE,
    AMB_COMMON_ROUTE,
    RECOVERY_ROUTE,
    PROFILE_ROUTE,
    AMB_PASSING_ROUTE,
    USERS_ROUTE,
    SEARCH_ROUTE,
    SMART_CENTER_ROUTE,
    EXAM_ROUTE,
    LICENSE_ROUTE,
    RESULT_ROUTE,
    STATIONS_ROUTE,
    ADMIN_ROUTE,
    ADMIN_USER_ROUTE, NURSING_ROUTE, NURSING_COMMON_ROUTE,
} from "../../Utils";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {ErrorBoundaryRoute} from "../ErrorBoundary/ErrorBoundary";
import MainLayout from "../UI/Layout/MainLayout";

const Home = React.lazy(() => import('../../pages/Home'));
const Users = React.lazy(() => import('../../pages/Users'));
const Profile = React.lazy(() => import('../../pages/Profile'));
const Logging = React.lazy(() => import('../../pages/Logging'));
const Outpatient = React.lazy(() => import('../../pages/Outpatient'));
const Common = React.lazy(() => import('../../pages/Common'));
const NamesTab = React.lazy(() => import('../UX/Common/Names/NamesTab'));
const OutpatientInspection = React.lazy(() => import('../UX/Common/Inspection/OutpatientInspection'));
const PassportTab = React.lazy(() => import('../UX/Common/Passport/PassportTab'));
const Medicine = React.lazy(() => import('../UX/Common/Medicine/Medicine'));
const TreatmentTab = React.lazy(() => import('../UX/Common/Treatment/TreatmentTab'));
const OutpatientExamTab = React.lazy(() => import('../UX/Common/Exam/OutpatientExamTab'));
const Disease = React.lazy(() => import('../UX/Common/Disease/Disease'));
const RecoveryPassword = React.lazy(() => import('../../pages/RecoveryPassword'));
const CasePassing = React.lazy(() => import('../../pages/CasePassing'));
const SearchPage = React.lazy(() => import('../../pages/Search'));
const NursingPage = React.lazy(() => import('../../pages/NursingPage'));
const NursingCommon = React.lazy(() => import('../../pages/NursingCommon'));

const HomePage = React.lazy(() => import('../../smartCenter/pages/HomePage'));
const Admin = React.lazy(() => import('../../smartCenter/pages/Admin'));
const UserSetting = React.lazy(() => import('../../smartCenter/pages/UserSetting'));
const Stations = React.lazy(() => import('../../smartCenter/pages/Stations'));
const Results = React.lazy(() => import('../../smartCenter/pages/Results'));
const License = React.lazy(() => import('../../smartCenter/pages/License'));
const Exam = React.lazy(() => import('../../smartCenter/pages/Exam'));

const AppRouter = observer(() => {
    const {user} = useContext(Context);

    const router = createBrowserRouter([
        {
            element: <MainLayout/>,
            errorElement: <ErrorBoundaryRoute/>,
            children: [
                {
                    path: HOME_ROUTE,
                    element: <Home/>,
                    errorElement: <ErrorBoundaryRoute/>
                },
                {
                    path: RECOVERY_ROUTE,
                    element: <RecoveryPassword/>,
                    errorElement: <ErrorBoundaryRoute/>
                },
                {
                    path: LOG_ROUTE,
                    element: user.auth ? <Logging/> : <Home/>,
                    errorElement: <ErrorBoundaryRoute/>
                },
                {
                    path: PROFILE_ROUTE,
                    element: user.auth && user.role === 'admin' ? <Profile/> : <Home/>,
                    errorElement: <ErrorBoundaryRoute/>
                },
                {
                    path: USERS_ROUTE,
                    element: user.auth && user.role === 'admin' ? <Users/> : <Home/>,
                    errorElement: <ErrorBoundaryRoute/>
                },
                {
                    path: AMB_PRIEM_ROUTE,
                    element: user.auth ? <Outpatient/> : <Home/>,
                    errorElement: <ErrorBoundaryRoute/>
                },
                {
                    path: NURSING_ROUTE,
                    element: user.auth && (user.role === 'admin' || user.curUser?.nursing?.length) ? <NursingPage/> : <Home/>,
                    errorElement: <ErrorBoundaryRoute/>
                },
                {
                    path: NURSING_COMMON_ROUTE,
                    element: user.auth && (user.role === 'admin' || user.email === 'demo@medvr.pro' || user.email === 'demo_sale@mvredtech.com') ?
                        <NursingCommon/> : <Home/>,
                    errorElement: <ErrorBoundaryRoute/>
                },
                {
                    path: AMB_COMMON_ROUTE,
                    element: user.auth && user.role === 'admin' ? <Common/> : <Home/>,
                    errorElement: <ErrorBoundaryRoute/>,
                    children: [
                        {
                            path: 'medicine',
                            element: <Medicine/>,
                        },
                        {
                            path: 'passport',
                            element: <PassportTab/>
                        },
                        {
                            path: 'inspection',
                            element: <OutpatientInspection/>
                        },
                        {
                            path: 'exam',
                            element: <OutpatientExamTab/>
                        },
                        {
                            path: 'treatment',
                            element: <TreatmentTab/>
                        },
                        {
                            path: 'names',
                            element: <NamesTab/>
                        },
                        {
                            path: 'disease',
                            element: <Disease/>
                        },
                        {
                            path: '*',
                            element: <></>
                        }
                    ]
                },
                {
                    path: AMB_PASSING_ROUTE,
                    element: user.auth ? <CasePassing/> : <Home/>
                },
                {
                    path: SEARCH_ROUTE,
                    element: user.auth && user.role ? <SearchPage/> : <Home/>
                },
                {
                    path: SMART_CENTER_ROUTE,
                    element: user.auth && (user.role === 'admin' || user.curUser.access?.indexOf('SC') !== -1)
                        ? <HomePage/>
                        : <Home/>,
                    errorElement: <ErrorBoundaryRoute/>,
                    children: [
                        {
                            path: ADMIN_ROUTE,
                            element: user.auth && user.role === 'admin' ? <Admin/> : <HomePage/>,
                            errorElement: <ErrorBoundaryRoute/>,
                            children: [
                                {
                                    path: ADMIN_USER_ROUTE,
                                    element: <UserSetting/>
                                }
                            ]
                        },
                        {
                            path: STATIONS_ROUTE,
                            element: user.auth && user.role === 'admin' ? <Stations/> : <HomePage/>,
                            errorElement: <ErrorBoundaryRoute/>,
                        },
                        {
                            path: RESULT_ROUTE,
                            element: user.auth && user.role === 'client' ? <Results/> : <HomePage/>,
                            errorElement: <ErrorBoundaryRoute/>
                        },
                        {
                            path: LICENSE_ROUTE,
                            element: user.auth && user.role === 'client' ? <License/> : <HomePage/>,
                            errorElement: <ErrorBoundaryRoute/>
                        },
                        {
                            path: EXAM_ROUTE,
                            element: user.auth && user.role === 'client' ? <Exam/> : <HomePage/>,
                            errorElement: <ErrorBoundaryRoute/>
                        },
                    ]
                },
                {
                    path: '*',
                    element: <Home/>,
                    errorElement: <ErrorBoundaryRoute/>
                }
            ]
        }
    ]);

    return <RouterProvider router={router}/>
});

export default AppRouter;