import {makeAutoObservable} from "mobx";

export default class UserStoreSC {
    //id пака пользователя
    packId: string
    nursingPackId: string

    constructor() {
        makeAutoObservable(this);
    }

    changeAuth(packId: string, nursingPackId: string) {
        if (packId) this.packId = packId;
        if (nursingPackId) this.nursingPackId = nursingPackId;
    }
}