import {observer} from "mobx-react-lite";
import React, {useContext, useMemo, useRef, useState} from "react";
import {Context} from "../../../index";
import styles from "./ContextMenu.module.scss";
import {CONTEXT_MENU_LIST} from "../../../Utils";
import {ReactComponent as DeleteIcon} from "../../../assets/img/urna.svg";
import {ReactComponent as EditIcon} from "../../../assets/img/edit-icon.svg";
import {ReactComponent as CopyIcon} from "../../../assets/img/copy-icon.svg";
import {ReactComponent as AddImg} from "../../../assets/img/plus-icon.svg";
import {ReactComponent as RefreshIcon} from "../../../assets/img/refresh_icon.svg";
import {ReactComponent as RecoveryIcon} from "../../../assets/img/recovery-icon.svg";
import {lang} from "../../../Utils/lang";
import {
    deleteCase,
    deleteCategory,
    updateExaminationTable
} from "../../../http/Outpatient";
import OutpatientCreateClassModal from "../../UI/Modal/Outpatient/OutpatientCreateClassModal";
import ModalEditCategoryCase from "../../UI/Modal/Outpatient/ModalEditCategoryCase";
import ModalCopyCase from "../../UI/Modal/Outpatient/ModalCopyCase";
import {updateLogs} from "../../../http/core";
import {deleteNursingCase} from "../../../http/Nursing";

const ContextMenu = observer(() => {
    const {ambulant, core_store, user, log_store, nursingStore} = useContext(Context);
    const [showEditCategory, setShowEditCategory] = useState(false);
    const [showCopyCase, setCopyCase] = useState(false);
    const [showAddCategory, setShowAddCategory] = useState(false);
    const infoRef = useRef({
        id: '',
        name: '',
        flag: false,
        type: '',
        anyInfo: null
    });

    //удаление кейса
    const deleteCaseOnClose = () => {
        if (infoRef.current.anyInfo.simulator === 'nursing') {
            deleteNursingCase(infoRef.current.id);
            nursingStore.deleteNursingCase(infoRef.current.id);
        } else {
            deleteCase(infoRef.current.id, {prevData: infoRef.current.name});
            ambulant.deleteCase(infoRef.current.id);
        }
    }

    //удаления категории
    const deleteCategoryOnClose = () => {
        deleteCategory(infoRef.current.id, {prevData: infoRef.current.name});
        ambulant.updateCategoryData({type: 'delete', categoryId: infoRef.current.id})
    }

    //Закрытие окна подверждения на удаление категории
    const handleCloseAcceptModal = async (arg: any) => {
        if (typeof arg === 'string' && arg === 'category') {
            deleteCategoryOnClose();
        } else if (typeof arg === 'string' && arg === 'case') {
            deleteCaseOnClose();
        }
        core_store.setShowAcceptModal(false);
    }

    const handleClickAction = async (action: string, e: any) => {
        e.stopPropagation();
        if (action === 'edit') {
            switch (core_store.contextMenu.type) {
                case 'case':
                case 'category':
                    infoRef.current.id = core_store.contextMenu.id;
                    infoRef.current.name = core_store.contextMenu.name[core_store.localization];
                    infoRef.current.flag = core_store.contextMenu.type === 'case';
                    infoRef.current.anyInfo = core_store.contextMenu.type === 'case' ? core_store.contextMenu.anyInfo?.simulator : null;
                    setShowEditCategory(true);
                    break;
            }
        } else if (action === 'delete') {
            switch (core_store.contextMenu.type) {
                case 'case':
                case 'category':
                    infoRef.current.id = core_store.contextMenu.id;
                    infoRef.current.name = core_store.contextMenu.name;
                    infoRef.current.anyInfo = {simulator: core_store.contextMenu.anyInfo?.simulator};
                    core_store.setModalAcceptParams({
                        headerText: core_store.contextMenu.type === 'case' ? lang.info.outpatient_49 : lang.info.outpatient_47,
                        bodyText: core_store.contextMenu.type === 'case' ? lang.info.outpatient_50 : lang.info.outpatient_48,
                        buttonText: lang.info.core_18,
                    });
                    core_store.setHideAccept(handleCloseAcceptModal)
                    core_store.setShowAcceptModal(true);
                    break;
                case 'table':
                    updateExaminationTable({
                        type: 'deleteRow',
                        rowId: core_store.contextMenu.anyInfo.rowId,
                        caseId: core_store.contextMenu.id,
                        tabName: core_store.contextMenu.name,
                        verifyFlag: core_store.contextMenu.anyInfo.verifyFlag,
                        rowIndex: core_store.contextMenu.anyInfo.rowIndex
                    });
                    break;
            }
        } else if (action === 'copy') {
            if (core_store.contextMenu.anyInfo.simulator === 'nursing') {
                nursingStore.setModalCopyCase({
                    caseId: core_store.contextMenu.id,
                    categoryId: core_store.contextMenu.anyInfo.categoryId
                })
            } else {
                infoRef.current.id = core_store.contextMenu.id;
                infoRef.current.anyInfo = core_store.contextMenu.anyInfo.categoryId;
                setCopyCase(true);
            }
        } else if (action === 'add') {
            switch (core_store.contextMenu.type) {
                case 'category':
                    setShowAddCategory(true);
                    break;
            }
        } else if (action === 'refresh') {
            log_store.needRefresh();
        } else if (action === 'recovery') {
            core_store.setLoading(true);
            updateLogs({
                id: core_store.contextMenu.id,
                collName: core_store.contextMenu.anyInfo
            });

        }
        core_store.closeContextMenu();
    }

    const context = useMemo(() => {
        if (core_store.contextMenu && core_store.contextMenu.show) {
            return CONTEXT_MENU_LIST[core_store.contextMenu.type];
        } else return undefined;
        // eslint-disable-next-line
    }, [core_store.contextMenu]);

    return (
        <>
            {context ?
                <div
                    id={'context_menu_div'}
                    className={styles.contextMenu}
                    style={{top: core_store.contextMenu.y, left: core_store.contextMenu.x}}
                >
                    {context.map((el: { [key: string]: string }) => {
                        if (core_store.contextMenu.type === 'case' && el.action === 'edit') {
                            if (core_store.contextMenu.anyInfo.typeCase === 'custom') {
                                return null;
                            }

                            if (user.role !== 'admin' && user.role !== 'user' && user.id !== core_store.contextMenu.anyInfo.author) {
                                return null;
                            }
                        }

                        if (core_store.contextMenu.type === 'case' && el.action === 'delete') {
                            if (core_store.contextMenu.anyInfo.typeCase === 'custom') return null;

                            if (user.role !== 'admin' && user.role !== 'user' && user.id !== core_store.contextMenu.anyInfo.author) {
                                return null;
                            }
                        }

                        let icon;

                        switch (el.action) {
                            case 'delete':
                                icon = <DeleteIcon/>;
                                break;
                            case 'edit':
                                icon = <EditIcon className={styles.editIconSvg}/>;
                                break;
                            case 'copy':
                                icon = <CopyIcon className={styles.copyIconSvg}/>;
                                break;
                            case 'add':
                                icon = <AddImg className={styles.addIconSvg}/>;
                                break;
                            case 'refresh':
                                icon = <RefreshIcon/>;
                                break;
                            default:
                                icon = <RecoveryIcon className={styles.recoveryIconSvg}/>;
                        }

                        return (
                            <div
                                key={'context_menu_' + el.action}
                                onMouseDown={handleClickAction.bind(this, el.action)}
                            >
                            <span>
                                {el.lang[core_store.localization]}
                            </span>
                                <div>{icon}</div>
                            </div>
                        )
                    })}
                </div>
                : <></>
            }
            {showEditCategory ?
                <ModalEditCategoryCase
                    show={showEditCategory}
                    onHide={() => setShowEditCategory(false)}
                    id={infoRef.current.id}
                    name={infoRef.current.name}
                    flag={infoRef.current.flag}
                    simulator={infoRef.current.anyInfo}
                />
                : <></>
            }
            {showCopyCase ?
                <ModalCopyCase
                    show={showCopyCase}
                    parentCaseId={infoRef.current.id}
                    categoryId={infoRef.current.anyInfo}
                    onHide={() => setCopyCase(false)}
                />
                : <></>
            }
            {showAddCategory ?
                <OutpatientCreateClassModal
                    show={showAddCategory}
                    onHide={() => setShowAddCategory(false)}
                />
                : <></>
            }
        </>
    )
});

export default ContextMenu;