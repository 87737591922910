import React, {ReactNode, useContext, useState} from "react";
import {lang} from "../../Utils/lang";
import {NavLink, useRouteError} from "react-router-dom";
import {LangLocalization} from "../../interface";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {Button} from "react-bootstrap";
import {sendErrorEmail} from "../../http/core";
import {VARIANT_BUTTON} from "../../Utils";

interface Props {
    children: ReactNode
    reloadPage: string
    localization: string
    text?: LangLocalization
    noText?: boolean
}

interface State {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error: ErrorEvent) {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return {hasError: true};
    }

    handleClick() {
        window.open(this.props.reloadPage, '_current');
    }

    render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return (<>
                    <strong className={'reload-title'}>{lang.errors.core_4[this.props.localization]}</strong>
                    <br/>
                    <NavLink to={this.props.reloadPage} onClick={this.handleClick.bind(this)}>
                        <strong className={'reload-href'}>{lang.errors.core_5[this.props.localization]}</strong>
                    </NavLink>
                </>
            );
        }

        return this.props.children;
    }
}

export class ErrorBoundaryMain extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error: ErrorEvent) {
        return {hasError: true};
    }


    render() {
        if (this.state.hasError) {
            return (<>
                    <strong className={'reload-title'}>{lang.errors.core_4[this.props.localization]}</strong>
                    <br/>
                    <strong className={'reload-href'}>{lang.errors.core_5[this.props.localization]}</strong>
                </>
            );
        }
        return this.props.children;
    }
}

export class ErrorBoundaryTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    handleReload() {
        return window.open(this.props.reloadPage, '_current');
    }

    render() {
        if (this.state.hasError && !this.props.noText) {
            return (
                <div>
                    <strong className={'reload-title'}>
                        {this.props.text ? this.props.text[this.props.localization] : lang.errors.core_4[this.props.localization]}
                    </strong>
                    <br/>
                    <strong className={'reload-href'} onClick={this.handleReload}>
                        {lang.errors.core_5[this.props.localization]}
                    </strong>
                </div>
            );
        } else if (this.state.hasError) {
            return <></>;
        } else return this.props.children;
    }
}

export const ErrorBoundaryRoute = observer(() => {
    const {core_store} = useContext(Context);
    const [sendError, setSendError] = useState(false);
    // @ts-ignore
    const error: Error = useRouteError();

    const handleSendError = () => {
        sendErrorEmail({
            text: error.stack+ '\nFrom CM',
            html: `<pre>${error.stack+ '\nFrom CM'}</pre>`
        }).finally(() => setSendError(true));
    }

    return (
        <div className={'error-router'}>
            <h3>
                {lang.errors.core_8[core_store.localization]}: {error.message}
            </h3>
            <br/>
            <pre className={'code'}>
                {error.stack}
            </pre>
            <br/>
            <Button disabled={sendError} variant={VARIANT_BUTTON} onClick={handleSendError}>
                {sendError
                    ? lang.info.core_74[core_store.localization]
                    : lang.errors.core_16[core_store.localization]
                }
            </Button>
            <Button style={{marginLeft: '1rem'}} variant={VARIANT_BUTTON} onClick={() => window.location.reload()}>
                {lang.errors.core_5[core_store.localization]}
            </Button>
        </div>
    );
});
