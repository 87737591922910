import {$api, $authApi, updateApi} from "./index";
import axios from "axios";
import {API_URl, detectedError} from "../Utils";

//Авторизация
export const auth = async (params: object) => {
    try {
        const {data} = await $authApi.post('api/user/login', params);
        if (data) {
            localStorage.setItem('token', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);

            if (data.std) {
                localStorage.setItem('std', data.std);
                updateApi(data.std);
            }
            return data;
        }
    } catch (ex) {
        if (ex instanceof ErrorEvent)
            return (ex.message);
        else if (axios.isAxiosError(ex))
            return (ex.response.data);
        else return (ex);
    }
}

export const logout = (refreshToken: string) => {
    $authApi.post('api/user/logout', {refreshToken}).catch(e => console.log(e));
}

export const resetPassword = async (email: string) => {
    return await $authApi.post('api/user/recovery', {email: email});
}

//Проверка авторизован ли?
export const check = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.get(`${API_URl}api/user/refresh`, {
            timeout: 5000,
            headers: {
                project: 'CM',
                refresh: refreshToken
            }
        });
        localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return response.data;
    } catch (e) {
        detectedError(e);
    }
}

//Получение юзеров
export const getUsers = async (id?: string) => {
    try {
        let response;
        if (id) {
            response = await $api.get('api/user?id=' + id);
        } else {
            response = await $api.get('api/user');
        }
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateUserEmail = async (params: object) => {
    try {
        await $api.put('api/user/email', params);
    } catch (ex) {
        return ex;
    }
}

//Бан юзера
export const banUser = (params: object) => {
    $api.put('api/user/ban', params).catch(ex => detectedError(ex));
}

export const userEditAccess = (params: object) => {
    $api.put('api/user/access', params).catch(ex => detectedError(ex));
}

export const userEditLang = (params: object) => {
    $api.put('api/user/lang', params).catch(ex => detectedError(ex));
}

export const userEditInfo = (params: object) => {
    $api.put('api/user/info', params).catch(ex => detectedError(ex));
}

export const userEditPass = (params: object) => {
    $api.put('api/user/password', params).catch(ex => detectedError(ex));
}

export const updateNursingName = (params: object) => {
    $api.put('api/user//nursing/name', params).catch(ex => detectedError(ex));
}

export const userMailing = async (params: object) => {
    try {
        const response = await $api.post('api/user/mailing', params);
        return response.data;
    } catch (ex) {
        if (axios.isAxiosError(ex)) return ex.response.data;
        else detectedError(ex);
    }
}

//Обновление паков юзера
export const updateUserPack = async (params: object) => {
    try {
        await $api.put('api/user/pack', params);
    } catch (ex) {
        if (axios.isAxiosError(ex)) return ex.response.data;
        else detectedError(ex);
    }
}

//Регистрация пользователя
export const registration = async (params: object) => {
    try {
        const response = await $api.post('api/user/registration', params);
        return response.data;
    } catch (ex: any) {
        if (ex && ex.data) return ex.data;
        else return ex;
    }
}

//Удаление пользователя
export const deleteUser = async (id: string, params: object) => {
    try {
        await $api.delete('api/user?id=' + id, {data: params});
    } catch (ex) {
        return detectedError(ex);
    }
}

//Проверка токена восстановления
export const checkTokenRecovery = async (token: string) => {
    try {
        const {data} = await $api.get('api/user/recovery?token=' + token);
        return data;
    } catch (ex: any) {
        return ex;
    }
}

//Проверка токена восстановления
export const tokenRecovery = async (params: object) => {
    try {
        const {data} = await $api.put('api/user/recovery', params);
        return data;
    } catch (ex: any) {
        return ex;
    }
}

export const getStd = async (email: string) => {
    const {data} = await $api.get('api/user/std?email=' + email);
    return data;
}

export const getFreePack = async () => {
    const {data} = await $api.get('api/user/pack');
    return data;
}