import {makeAutoObservable} from "mobx";

export interface INewElement {
    type: string;
    params: number[]
}

export type IDataTreeElement = {
    id: string;
    type: string;
    params?: any[];
    width?: number | null;
    height?: number | null;
};

export type ISelectedElement = {
    id: string;
    type: string;
    typeElement: string;
};

export class NursingFlowchart {
    treeElements: Map<string, IDataTreeElement> = new Map();
    newElement: INewElement | null = null;
    selectedElement: ISelectedElement | null = null;
    isOneElement = false;

    constructor() {
        makeAutoObservable(this);
    }

    setSelectedElement = (data: ISelectedElement | null) => {
        this.isOneElement = !!data;
        this.selectedElement = data;
    };

    setIsOneElement(data: boolean) {
        this.isOneElement = data;
    }

    updateParamsElementTree(id: string, data: any[]) {
        const elem = this.treeElements.get(id);

        if (!elem) return;

        elem.params = data;
    }

    setElementTree(key: string, data: IDataTreeElement) {
        this.treeElements.set(key, data);
    }

    clearTree = () => {
        this.treeElements.clear();
    };

    deleteElement = (id: string) => {
        this.treeElements.delete(id);
    };

    initialNewElement() {
        this.newElement = {
            type: '',
            params: []
        }
    }

    setNewElement<T extends keyof INewElement>(type: T, data: INewElement[T]) {
        this.newElement[type] = data;
    }

    clearNewElement() {
        this.newElement = null;
    }
}