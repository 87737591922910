import {detectedError} from "../Utils";
import axios from "axios";
import {$api} from "../../http";

export const getPacks = async (userId: string) => {
    try {
        const response = await $api.get('api/main/packs?userId=' + userId);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updatePackId = async (params: object) => {
    try {
        const response = await $api.put('api/user/packId', params);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const updatePackPackage = async (params: object) => {
    try {
        const response = await $api.put('api/main/pack/package', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updatePackDateActivate = async (params: object) => {
    try {
        const response = await $api.put('api/main/pack/package/date', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updatePackLicenseLimit = async (params: object) => {
    try {
        const response = await $api.put('api/main/pack/license/limit', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateNursingPackLicenseLimit = async (params: object) => {
    try {
        const response = await $api.put('api/nursing/license/limit', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getPackById = async (id: string) => {
    try {
        const response = await $api.get('api/main/pack?id=' + id);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getNursingPackById = async (packId: string) => {
    try {
        const response = await $api.get(`api/nursing/pack?packId=${packId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getUserLicense = async (packId: string) => {
    try {
        const response = await $api.get(`api/main/pack/license/user?packId=${packId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getNursingUserLicense = async (packId: string) => {
    try {
        const response = await $api.get(`api/nursing/license/user?packId=${packId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getNursingStationLicense = async (packId: string) => {
    try {
        const response = await $api.get(`api/nursing/license/station?packId=${packId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateUserLicense = async (params: object) => {
    try {
        const response = await $api.put('api/main/pack/license/user', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateNursingUserLicense = async (params: object) => {
    try {
        const response = await $api.put('api/nursing/license/user', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertUserLicense = async (params: object) => {
    try {
        const response = await $api.post('api/main/pack/license/user', params);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const insertNursingUserLicense = async (params: object) => {
    try {
        const response = await $api.post('api/nursing/license/user', params);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const insertNursingStationLicense = async (params: object) => {
    try {
        const response = await $api.post('api/nursing/license/station', params);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const deleteUserLicense = async (id: string) => {
    try {
        const response = await $api.delete(`api/main/pack/license/user?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteNursingLicense = async (id: string, type: string) => {
    try {
        const response = await $api.delete(`api/nursing/license?id=${id}&type=${type}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getStationLicense = async (packId: string) => {
    try {
        const response = await $api.get(`api/main/pack/license/station?packId=${packId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateStationLicense = async (params: object) => {
    try {
        const response = await $api.put('api/main/pack/license/station', params);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const updateNursingStationLicense = async (params: object) => {
    try {
        const response = await $api.put('api/nursing/license/station', params);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const insertStationLicense = async (params: object) => {
    try {
        const response = await $api.post('api/main/pack/license/station', params);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const deleteStationLicense = async (id: string) => {
    try {
        const response = await $api.delete(`api/main/pack/license/station?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteNursingPackLicense = async (id: string, type: string) => {
    try {
        const response = await $api.delete(`api/nursing/license?id=${id}&type=${type}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const uploadLicenseFile = async (params: object) => {
    try {
        const response = await $api.post('api/main/pack/license/file', params, {
            headers: {'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
        });
        return response.data;
    } catch (ex) {
        if (axios.isAxiosError(ex)) return (ex.response.data);
        detectedError(ex);
    }
}

export const packLicenseMailing = async (params: object) => {
    try {
        await $api.post('api/main/pack/license/mailing', params)
    } catch (ex) {
        detectedError(ex);
    }
}

export const packNursingLicenseMailing = async (params: object) => {
    try {
        await $api.post('api/nursing/license/mailing', params)
    } catch (ex) {
        detectedError(ex);
    }
}

export const updatePackLicenseBlocking = async (params: object) => {
    try {
        await $api.put('api/main/pack/license/blocking', params)
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateNursingLicenseBlocking = async (params: object) => {
    try {
        await $api.put('api/nursing/license/blocking', params)
    } catch (ex) {
        detectedError(ex);
    }
}

export const updatePackAcceptMac = async (params: object) => {
    try {
        const response = await $api.put('api/main/pack/accept', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateNursingPackAcceptMac = async (params: object) => {
    try {
        const response = await $api.put('api/nursing/license/accept', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getPackInfo = async (packId: string) => {
    try {
        const response = await $api.get(`api/main/pack/info?packId=${packId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Создание экзамена
export const insertExam = async (params: object) => {
    try {
        await $api.post('api/main/exam', params);
    } catch (ex) {
        detectedError(ex);
    }
}

export const getExam = async (packId: string) => {
    try {
        const response = await $api.get('api/main/exam?packId=' + packId);
        return response.data;
    } catch (ex) {
        // @ts-ignore
        if (ex.response.data.error === 'Exam list empty.') return [];
        detectedError(ex);
    }
}

export const updateExam = async (params: object) => {
    try {
        await $api.put('api/main/exam', params);
    } catch (ex) {
        detectedError(ex);
    }
}

//отправить письмо с ошибкой разработчикам
export const sendErrorEmail = async (params: object) => {
    try {
        await $api.post('api/error', params);
    } catch (ex) {
        detectedError(ex);
    }
}


//получение имен из результатов
export const getResultNames = async (packId: string) => {
    try {
        const response = await $api.get('api/main/result/name?packId=' + packId);
        return response.data;
    } catch (e) {
        console.log(e);
    }
}

export const getEmailByName = async (packId: string, name: string) => {
    try {
        const response = await $api.get('api/main/pack/license/email?name=' + name + '&packId=' + packId);
        return response.data;
    } catch (e) {
        console.log(e);
    }
}