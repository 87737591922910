import React, {Suspense, useContext} from 'react';
import {observer} from "mobx-react-lite";
import HeaderMain from "../Header/HeaderMain";
import {ErrorBoundary} from '../../ErrorBoundary/ErrorBoundary';
import {Context} from "../../../index";
import {Spinner} from "react-bootstrap";
import {Outlet} from "react-router-dom";

const MainLayout = observer(() => {
    const {core_store} = useContext(Context);

    return (
        <>
            <HeaderMain/>
            <main className={'container'}>
                <ErrorBoundary
                    localization={core_store.localization}
                    reloadPage={document.URL}
                >
                    <Suspense fallback={<Spinner animation="border" variant="primary"/>}>
                        <Outlet/>
                    </Suspense>
                </ErrorBoundary>
            </main>
        </>
    );
});

export default MainLayout;