import {useContext} from 'react';
import {NavLink} from "react-router-dom";
import {HOME_ROUTE} from "../../../Utils";
import {observer} from "mobx-react-lite";
import {lang} from "../../../Utils/lang";
import NavBarMain from "../NavBar/NavBarMain";
import styles from "./HederMain.module.scss"
import {Context} from "../../../index";

const HeaderMain = observer(() => {
    const {core_store} = useContext(Context);

    return (
        <header className={styles.header}>
            <div className={styles.companyName}>
                {core_store.modalTechnical ?
                    <strong>{lang.info.name_company}</strong>
                    :
                    <NavLink to={HOME_ROUTE}>
                        <strong>{lang.info.name_company}</strong>
                    </NavLink>
                }
            </div>
            <div className={styles.navBarBlock}>
                <NavBarMain/>
            </div>
        </header>
    );
});

export default HeaderMain;