import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";

const ModalImageFullScreen = observer(() => {
    const {core_store} = useContext(Context);

    const handleCloseByMouse = (e: any) => {
        if (e.target.localName === 'img') {
            return;
        } else {
            core_store.setParamsFullScreen(false, '');
        }
    }

    /*const handleClose = (e: any) => {
        if (e.code === 'Escape') {
            document.removeEventListener('keydown', handleClose);
            core_store.setParamsFullScreen(false, '');
        }
    }
    document.addEventListener('keydown', handleClose);*/

    if (core_store.showFullScreen) {
        return (
            <div
                className={'image-fullscreen'}
                onClick={handleCloseByMouse}
            >
                <img src={core_store.hrefFullScreen} alt={''}/>
            </div>
        );
    } else return <></>
});

export default ModalImageFullScreen;