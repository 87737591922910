import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Modal} from "react-bootstrap";
import {Context} from "../../../index";
import {lang} from '../../../Utils/lang';

const ModalError = observer(() => {
    const {core_store} = useContext(Context);

    const handleClose = () => {
        return core_store.closeErrorModal();
    }

    return (
        <Modal
            fullscreen={core_store.tabMode || core_store.mobileMode}
            show={core_store.errorParams.errorShow}
            onHide={handleClose}
        >
            <Modal.Header
                closeButton
            >
                <Modal.Title>
                    {core_store.errorParams.errorTitle
                        ? core_store.errorParams.errorTitle
                        : lang.errors.core_8[core_store.localization]
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {core_store.errorParams.errorText}
            </Modal.Body>
        </Modal>
    );
})

export default ModalError;