import {Lang} from "../interface";

export const lang: Lang = {
    info: {
        core_1: {
            ru: 'СМАРТ ЦЕНТР',
            en: 'SMART CENTER',
            tr: 'SMART CENTER',
            es: 'SMART CENTER',
            ar: 'SMART CENTER',
            de: 'SMART CENTER',
            pt: 'SMART CENTER',
            fr: 'SMART CENTER',
            zh: 'SMART CENTER',
            ko: 'SMART CENTER',
            ja: 'SMART CENTER',
            th: 'SMART CENTER',
            he: 'SMART CENTER',
            zh_tw: 'SMART CENTER',
            fil: 'SMART CENTER'
        },
        core_2: {
            ru: 'Фильтр',
            en: 'Filter',
            tr: 'Filtre',
            es: 'Filtro',
            ar: 'تصفية',
            de: 'Filter',
            pt: 'Filtro',
            fr: 'Filtre',
            zh: '筛选',
            ko: '필터',
            ja: 'フィルター',
            th: 'กรอง',
            he: 'סינון',
            zh_tw: '过滤',
            fil: 'Salain'
        },
        core_3: {
            ru: 'Применить',
            en: 'Apply',
            tr: 'Uygula',
            es: 'Aplicar',
            ar: 'تطبيق',
            de: 'Anwenden',
            pt: 'Aplicar',
            fr: 'Appliquer',
            zh: '应用',
            ko: '적용',
            ja: '適用',
            th: 'ประยุกต์',
            he: 'החל',
            zh_tw: '应用',
            fil: 'I-apply'
        },
        core_4: {
            ru: 'Сбросить',
            en: 'Reset',
            tr: 'Sıfırla',
            es: 'Reiniciar',
            ar: 'إعادة تعيين',
            de: 'Zurücksetzen',
            pt: 'Resetar',
            fr: 'Réinitialiser',
            zh: '重置',
            ko: '재설정',
            ja: 'リセット',
            th: 'รีเซ็ต',
            he: 'אפס',
            zh_tw: '重置',
            fil: 'I-reset'
        },
        core_5: {
            ru: 'Поиск',
            en: 'Search',
            tr: 'Ara',
            es: 'Buscar',
            ar: 'بحث',
            de: 'Suche',
            pt: 'Pesquisar',
            fr: 'Chercher',
            zh: '搜索',
            ko: '검색',
            ja: '検索',
            th: 'ค้นหา',
            he: 'חפש',
            zh_tw: '搜索',
            fil: 'Maghanap'
        },
        core_6: {
            ru: 'Инфо',
            en: 'Info',
            tr: 'Bilgi',
            es: 'Info',
            ar: 'معلومات',
            de: 'Info',
            pt: 'Informação',
            fr: 'Info',
            zh: '信息',
            ko: '정보',
            ja: '情報',
            th: 'ข้อมูล',
            he: 'מידע',
            zh_tw: '信息',
            fil: 'Impormasyon'
        },
        core_7: {
            ru: 'Скрыть таблицу',
            en: 'Hide table',
            tr: 'Tabloyu gizle',
            es: 'Ocultar tabla',
            ar: 'إخفاء الجدول',
            de: 'Tabelle verbergen',
            pt: 'Esconder tabela',
            fr: 'Cacher la table',
            zh: '隐藏表格',
            ko: '표 숨기기',
            ja: 'テーブルを非表示',
            th: 'ซ่อนตาราง',
            he: 'הסתר טבלה',
            zh_tw: '隐藏表格',
            fil: 'Itago ang talahanayan'
        },
        core_8: {
            ru: 'Показать таблицу',
            en: 'Show table',
            tr: 'Tabloyu göster',
            es: 'Mostrar tabla',
            ar: 'إظهار الجدول',
            de: 'Tabelle anzeigen',
            pt: 'Mostrar tabela',
            fr: 'Afficher la table',
            zh: '显示表格',
            ko: '표 보기',
            ja: 'テーブルを表示',
            th: 'แสดงตาราง',
            he: 'הצג טבלה',
            zh_tw: '显示表格',
            fil: 'Ipakita ang talahanayan'
        },
        core_9: {
            ru: 'В начало',
            en: 'To beginning',
            tr: 'Başa dön',
            es: 'Al principio',
            ar: 'إلى البداية',
            de: 'Zum Anfang',
            pt: 'Para o início',
            fr: 'Au début',
            zh: '回到顶部',
            ko: '처음으로',
            ja: '先頭へ',
            th: 'ไปที่จุดเริ่มต้น',
            he: 'לתחילה',
            zh_tw: '回到开始',
            fil: 'Sa simula'
        },
        core_10: {
            ru: 'Безопасность',
            en: 'Safety',
            tr: 'Güvenlik',
            es: 'Seguridad',
            ar: 'السلامة',
            de: 'Sicherheit',
            pt: 'Segurança',
            fr: 'Sécurité',
            zh: '安全性',
            ko: '안전',
            ja: '安全',
            th: 'ความปลอดภัย',
            he: 'בטיחות',
            zh_tw: '安全',
            fil: 'Kaligtasan'
        },
        core_11: {
            ru: 'Закрыть',
            en: 'Close',
            tr: 'Kapat',
            es: 'Cerrar',
            ar: 'إغلاق',
            de: 'Schließen',
            pt: 'Fechar',
            fr: 'Fermer',
            zh: '关闭',
            ko: '닫기',
            ja: '閉じる',
            th: 'ปิด',
            he: 'סגור',
            zh_tw: '关闭',
            fil: 'Isara'
        },
        core_12: {
            ru: 'Открыть',
            en: 'Open',
            tr: 'Aç',
            es: 'Abrir',
            ar: 'فتح',
            de: 'Öffnen',
            pt: 'Abrir',
            fr: 'Ouvrir',
            zh: '打开',
            ko: '열기',
            ja: '開く',
            th: 'เปิด',
            he: 'פתח',
            zh_tw: '打开',
            fil: 'Buksan'
        },
        core_13: {
            ru: 'Имя',
            en: 'Name',
            tr: 'İsim',
            es: 'Nombre',
            ar: 'اسم',
            de: 'Name',
            pt: 'Nome',
            fr: 'Nom',
            zh: '名称',
            ko: '이름',
            ja: '名前',
            th: 'ชื่อ',
            he: 'שם',
            zh_tw: '名字',
            fil: 'Pangalan'
        },
        core_14: {
            ru: 'Группа',
            en: 'Group',
            tr: 'Grup',
            es: 'Grupo',
            ar: 'مجموعة',
            de: 'Gruppe',
            pt: 'Grupo',
            fr: 'Groupe',
            zh: '组',
            ko: '그룹',
            ja: 'グループ',
            th: 'กลุ่ม',
            he: 'קבוצה',
            zh_tw: '组',
            fil: 'Grupo'
        },
        core_15: {
            ru: 'Симулятор',
            en: 'Simulator',
            tr: 'Simülatör',
            es: 'Simulador',
            ar: 'محاكاة',
            de: 'Simulator',
            pt: 'Simulador',
            fr: 'Simulateur',
            zh: '模拟器',
            ko: '시뮬레이터',
            ja: 'シミュレータ',
            th: 'สมมติ',
            he: 'מסמך',
            zh_tw: '模拟器',
            fil: 'Simulator'
        },
        core_16: {
            ru: 'Клинический случай',
            en: 'Clinical case',
            tr: 'Klinik vaka',
            es: 'Caso clínico',
            ar: 'حالة إكلينيكية',
            de: 'Klinischer Fall',
            pt: 'Caso clínico',
            fr: 'Cas clinique',
            zh: '临床案例',
            ko: '임상 사례',
            ja: '臨床症例',
            th: 'เคสคลินิก',
            he: 'מקרה קליני',
            zh_tw: '临床案例',
            fil: 'Klinikal na kaso'
        },
        core_17: {
            ru: 'Дата',
            en: 'Date',
            tr: 'Tarih',
            es: 'Fecha',
            ar: 'تاريخ',
            de: 'Datum',
            pt: 'Data',
            fr: 'Date',
            zh: '日期',
            ko: '날짜',
            ja: '日付',
            th: 'วันที่',
            he: 'תאריך',
            zh_tw: '日期',
            fil: 'Petsa'
        },
        core_18: {
            ru: 'Cтатистика',
            en: 'Statistics',
            tr: 'İstatistikler',
            es: 'Estadísticas',
            ar: 'إحصائيات',
            de: 'Statistiken',
            pt: 'Estatísticas',
            fr: 'Statistiques',
            zh: '统计数据',
            ko: '통계',
            ja: '統計',
            th: 'สถิติ',
            he: 'סטטיסטיקה',
            zh_tw: '统计',
            fil: 'Estadistika'
        },
        core_19: {
            ru: 'Диалоги',
            en: 'Dialogues',
            tr: 'Diyaloglar',
            es: 'Diálogos',
            ar: 'حوارات',
            de: 'Dialoge',
            pt: 'Diálogos',
            fr: 'Dialogues',
            zh: '对话框',
            ko: '대화',
            ja: '対話',
            th: 'บทสนทนา',
            he: 'שיחות',
            zh_tw: '对话',
            fil: 'Mga Diyalogo'
        },
        core_20: {
            ru: 'Обследование',
            en: 'Examination',
            tr: 'Muayene',
            es: 'Examen',
            ar: 'فحص',
            de: 'Untersuchung',
            pt: 'Exame',
            fr: 'Examen',
            zh: '检查',
            ko: '검사',
            ja: '検査',
            th: 'การตรวจ',
            he: 'בדיקה',
            zh_tw: '检查',
            fil: 'Pagsusuri'
        },
        core_21: {
            ru: 'Диагнозы',
            en: 'Diagnoses',
            tr: 'Tanılar',
            es: 'Diagnósticos',
            ar: 'تشخيصات',
            de: 'Diagnosen',
            pt: 'Diagnósticos',
            fr: 'Diagnostic',
            zh: '诊断',
            ko: '진단',
            ja: '診断',
            th: 'การวินิจฉัย',
            he: 'אבחנות',
            zh_tw: '诊断',
            fil: 'Mga Diagnoses'
        },
        core_22: {
            ru: 'Лечение',
            en: 'Treatment',
            tr: 'Tedavi',
            es: 'Tratamiento',
            ar: 'علاج',
            de: 'Behandlung',
            pt: 'Tratamento',
            fr: 'Traitement',
            zh: '治疗',
            ko: '치료',
            ja: '治療',
            th: 'การรักษา',
            he: 'טיפול',
            zh_tw: '治疗',
            fil: 'Paggamot'
        },
        core_23: {
            ru: 'Результат',
            en: 'Result',
            tr: 'Sonuç',
            es: 'Resultado',
            ar: 'نتيجة',
            de: 'Ergebnis',
            pt: 'Resultado',
            fr: 'Résultat',
            zh: '结果',
            ko: '결과',
            ja: '結果',
            th: 'ผลลัพธ์',
            he: 'תוצאה',
            zh_tw: '结果',
            fil: 'Resulta'
        },
        core_24: {
            ru: 'Средний балл',
            en: 'Average score',
            tr: 'Ortalama puan',
            es: 'Puntuación media',
            ar: 'المتوسط ​​المرجح',
            de: 'Durchschnittliche Punktzahl',
            pt: 'Pontuação média',
            fr: 'Score moyen',
            zh: '平均分数',
            ko: '평균 점수',
            ja: '平均スコア',
            th: 'คะแนนเฉลี่ย',
            he: 'ניקוד ממוצע',
            zh_tw: '平均分数',
            fil: 'Katamtamang marka'
        },
        core_25: {
            ru: 'График',
            en: 'Chart',
            tr: 'Grafik',
            es: 'Gráfico',
            ar: 'رسم بياني',
            de: 'Diagramm',
            pt: 'Gráfico',
            fr: 'Graphique',
            zh: '图表',
            ko: '차트',
            ja: 'チャート',
            th: 'แผนภูมิ',
            he: 'תרשים',
            zh_tw: '图表',
            fil: 'Tsart'
        },
        core_26: {
            ru: 'График баллов',
            en: 'Score chart',
            tr: 'Puan grafik',
            es: 'Gráfico de puntuación',
            ar: 'رسم بياني للنتائج',
            de: 'Punktediagramm',
            pt: 'Gráfico de pontuação',
            fr: 'Graphique des scores',
            zh: '分数图表',
            ko: '점수 차트',
            ja: 'スコアチャート',
            th: 'แผนภูมิคะแนน',
            he: 'תרשים ניקוד',
            zh_tw: '得分图',
            fil: 'Tsart ng Marka'
        },
        core_27: {
            ru: 'Балл',
            en: 'Score',
            tr: 'Puan',
            es: 'Puntuación',
            ar: 'النتيجة',
            de: 'Punktzahl',
            pt: 'Pontuação',
            fr: 'Score',
            zh: '得分',
            ko: '점수',
            ja: 'スコア',
            th: 'คะแนน',
            he: 'ניקוד',
            zh_tw: '得分',
            fil: 'Marka'
        },
        core_29: {
            ru: 'Закрыть график',
            en: 'Close chart',
            tr: 'Grafiği kapat',
            es: 'Cerrar gráfico',
            ar: 'إغلاق الرسم البياني',
            de: 'Diagramm schließen',
            pt: 'Fechar gráfico',
            fr: 'Fermer le graphique',
            zh: '关闭图表',
            ko: '차트 닫기',
            ja: 'チャートを閉じる',
            th: 'ปิดแผนภูมิ',
            he: 'סגור תרשים',
            zh_tw: '关闭图表',
            fil: 'Isara ang tsart'
        },
        core_30: {
            ru: 'Язык',
            en: 'Language',
            tr: 'Dil',
            es: 'Idioma',
            ar: 'لغة',
            de: 'Sprache',
            pt: 'Idioma',
            fr: 'Langue',
            zh: '语言',
            ko: '언어',
            ja: '言語',
            th: 'ภาษา',
            he: 'שפה',
            zh_tw: '语言',
            fil: 'Wika'
        },
        core_31: {
            ru: 'ФИО',
            en: 'Full name',
            tr: 'Tam adı',
            es: 'Nombre completo',
            ar: 'الاسم الكامل',
            de: 'Vollständiger Name',
            pt: 'Nome completo',
            fr: 'Nom complet',
            zh: '姓名',
            ko: '전체 이름',
            ja: 'フルネーム',
            th: 'ชื่อเต็ม',
            he: 'שם מלא',
            zh_tw: '全名',
            fil: 'Buong pangalan'
        },
        core_32: {
            ru: 'Фамилия:',
            en: 'Last name:',
            tr: 'Soyadı',
            es: 'Apellido:',
            ar: 'اسم العائلة:',
            de: 'Nachname:',
            pt: 'Sobrenome:',
            fr: 'Nom de famille:',
            zh: '姓氏：',
            ko: '성',
            ja: '姓：',
            th: 'นามสกุล:',
            he: 'שם משפחה:',
            zh_tw: '姓：',
            fil: 'Apelyido:'
        },
        core_33: {
            ru: 'Отчество',
            en: 'Middle name',
            tr: 'İkinci adı',
            es: 'Segundo nombre',
            ar: 'الاسم الأوسط',
            de: 'Zweiter Vorname',
            pt: 'Nome do meio',
            fr: 'Deuxième prénom',
            zh: '中间名',
            ko: '중간 이름',
            ja: 'ミドルネーム',
            th: 'ชื่อกลาง',
            he: 'שם אמצעי',
            zh_tw: '中间名',
            fil: 'Gitnang pangalan'
        },
        core_34: {
            ru: 'Редактировать',
            en: 'Edit',
            tr: 'Düzenle',
            es: 'Editar',
            ar: 'تعديل',
            de: 'Bearbeiten',
            pt: 'Editar',
            fr: 'Éditer',
            zh: '编辑',
            ko: '편집',
            ja: '編集',
            th: 'แก้ไข',
            he: 'ערוך',
            zh_tw: '编辑',
            fil: 'I-edit'
        },
        core_35: {
            ru: 'Сохранить',
            en: 'Save',
            tr: 'Kaydet',
            es: 'Guardar',
            ar: 'حفظ',
            de: 'Speichern',
            pt: 'Salvar',
            fr: 'Sauvegarder',
            zh: '保存',
            ko: '저장',
            ja: '保存',
            th: 'บันทึก',
            he: 'שמור',
            zh_tw: '保存',
            fil: 'I-save'
        },
        core_36: {
            ru: 'Отмена',
            en: 'Cancel',
            tr: 'İptal',
            es: 'Cancelar',
            ar: 'إلغاء',
            de: 'Abbrechen',
            pt: 'Cancelar',
            fr: 'Annuler',
            zh: '取消',
            ko: '취소',
            ja: 'キャンセル',
            th: 'ยกเลิก',
            he: 'בטל',
            zh_tw: '取消',
            fil: 'I-kansela'
        },
        core_37: {
            ru: 'Профиль',
            en: 'Profile',
            tr: 'Profil',
            es: 'Perfil',
            ar: 'الملف الشخصي',
            de: 'Profil',
            pt: 'Perfil',
            fr: 'Profil',
            zh: '个人资料',
            ko: '프로필',
            ja: 'プロファイル',
            th: 'โปรไฟล์',
            he: 'פרופיל',
            zh_tw: '配置文件',
            fil: 'Profile'
        },
        core_38: {
            ru: 'Выберите профиль',
            en: 'Select profile',
            tr: 'Profili seç',
            es: 'Seleccionar perfil',
            ar: 'اختر الملف الشخصي',
            de: 'Profil auswählen',
            pt: 'Selecionar perfil',
            fr: 'Sélectionner le profil',
            zh: '选择个人资料',
            ko: '프로필 선택',
            ja: 'プロファイルを選択',
            th: 'เลือกโปรไฟล์',
            he: 'בחר פרופיל',
            zh_tw: '选择配置文件',
            fil: 'Piliin ang profile'
        },
        core_39: {
            ru: 'Mobile лицензия (VR устройство)',
            en: 'Mobile license (VR device)',
            tr: 'Mobil lisans (VR cihaz)',
            es: 'Licencia móvil (dispositivo VR)',
            ar: 'رخصة الجوال (جهاز الواقع الافتراضي)',
            de: 'Mobile Lizenz (VR-Gerät)',
            pt: 'Licença móvel (dispositivo VR)',
            fr: 'Licence mobile (appareil VR)',
            zh: '移动许可证（VR设备）',
            ko: '모바일 라이선스 (VR 장치)',
            ja: 'モバイルライセンス（VRデバイス）',
            th: 'ใบอนุญาตเคลื่อนที่ (อุปกรณ์ VR)',
            he: 'רישיון למכשיר נייד (VR)',
            zh_tw: '移动许可证（VR设备）',
            fil: 'Lisensya sa Mobile (VR device)'
        },
        core_40: {
            ru: 'Пользовательская лицензия',
            en: 'User license',
            tr: 'Kullanıcı lisansı',
            es: 'Licencia de usuario',
            ar: 'رخصة المستخدم',
            de: 'Benutzerlizenz',
            pt: 'Licença de usuário',
            fr: 'Licence utilisateur',
            zh: '用户许可证',
            ko: '사용자 라이선스',
            ja: 'ユーザーライセンス',
            th: 'ใบอนุญาตผู้ใช้',
            he: 'רישיון משתמש',
            zh_tw: '用户许可证',
            fil: 'Lisensya ng User'
        },
        core_41: {
            ru: 'Удалить',
            en: 'Delete',
            tr: 'Sil',
            es: 'Eliminar',
            ar: 'حذف',
            de: 'Löschen',
            pt: 'Excluir',
            fr: 'Supprimer',
            zh: '删除',
            ko: '삭제',
            ja: '削除',
            th: 'ลบ',
            he: 'מחק',
            zh_tw: '删除',
            fil: 'Burahin'
        },
        core_42: {
            ru: 'Лицензия',
            en: 'License',
            tr: 'Lisans',
            es: 'Licencia',
            ar: 'ترخيص',
            de: 'Lizenz',
            pt: 'Licença',
            fr: 'Licence',
            zh: '许可证',
            ko: '라이선스',
            ja: 'ライセンス',
            th: 'ใบอนุญาต',
            he: 'רישיון',
            zh_tw: '许可证',
            fil: 'Lisensya'
        },
        core_43: {
            ru: 'Платформа',
            en: 'Platform',
            tr: 'Platform',
            es: 'Plataforma',
            ar: 'منصة',
            de: 'Plattform',
            pt: 'Plataforma',
            fr: 'Plateforme',
            zh: '平台',
            ko: '플랫폼',
            ja: 'プラットフォーム',
            th: 'แพลตฟอร์ม',
            he: 'פלטפורמה',
            zh_tw: '平台',
            fil: 'Plataforma'
        },
        core_44: {
            ru: 'Подключиться',
            en: 'Connect',
            tr: 'Bağlan',
            es: 'Conectar',
            ar: 'اتصال',
            de: 'Verbinden',
            pt: 'Conectar',
            fr: 'Connecter',
            zh: '连接',
            ko: '연결',
            ja: '接続する',
            th: 'เชื่อมต่อ',
            he: 'התחבר',
            zh_tw: '连接',
            fil: 'Kumonekta'
        },
        core_45: {
            ru: 'Экзамен',
            en: 'Exam',
            tr: 'Sınav',
            es: 'Examen',
            ar: 'امتحان',
            de: 'Prüfung',
            pt: 'Exame',
            fr: 'Examen',
            zh: '考试',
            ko: '검사',
            ja: '検査',
            th: 'การสอบ',
            he: 'בחינה',
            zh_tw: '考试',
            fil: 'Pagsusulit'
        },
        core_46: {
            ru: 'Добавить',
            en: 'Add',
            tr: 'Ekle',
            es: 'Añadir',
            ar: 'إضافة',
            de: 'Hinzufügen',
            pt: 'Adicionar',
            fr: 'Ajouter',
            zh: '添加',
            ko: '추가',
            ja: '追加',
            th: 'เพิ่ม',
            he: 'הוסף',
            zh_tw: '添加',
            fil: 'Magdagdag'
        },
        core_47: {
            ru: 'Копировать код',
            en: 'Copy code',
            tr: 'Kodu kopyala',
            es: 'Copiar código',
            ar: 'نسخ الكود',
            de: 'Code kopieren',
            pt: 'Copiar código',
            fr: 'Copier le code',
            zh: '复制代码',
            ko: '코드 복사',
            ja: 'コードをコピー',
            th: 'คัดลอกโค้ด',
            he: 'העתק קוד',
            zh_tw: '复制代码',
            fil: 'Kopyahin ang code'
        },
        core_48: {
            ru: 'Добавить экзамен',
            en: 'Add exam',
            tr: 'Sınav ekle',
            es: 'Añadir examen',
            ar: 'إضافة امتحان',
            de: 'Prüfung hinzufügen',
            pt: 'Adicionar exame',
            fr: 'Ajouter un examen',
            zh: '添加考试',
            ko: '검사 추가',
            ja: '検査を追加',
            th: 'เพิ่มการสอบ',
            he: 'הוסף בחינה',
            zh_tw: '添加考试',
            fil: 'Magdagdag ng pagsusulit'
        },
        core_49: {
            ru: 'Отправить ошибку разработчикам',
            en: 'Send error to developers',
            tr: 'Hatayı geliştiricilere gönder',
            es: 'Enviar error a los desarrolladores',
            ar: 'إرسال الخطأ إلى المطورين',
            de: 'Fehler an Entwickler senden',
            pt: 'Enviar erro para os desenvolvedores',
            fr: "Envoyer l'erreur aux développeurs",
            zh: '向开发人员报告错误。',
            ko: '오류를 개발자에게 보내세요',
            ja: 'エラーを開発者に送信',
            th: 'ส่งข้อผิดพลาดให้กับผู้พัฒนา',
            he: 'שלח שגיאה למפתחים',
            zh_tw: '发送错误给开发者',
            fil: 'Ipadala ang error sa mga developer'
        },
        core_50: {
            ru: 'Перезагрузить страницу',
            en: 'Reload page',
            tr: 'Sayfayı yenile',
            es: 'Recargar página',
            ar: 'أعد تحميل الصفحة',
            de: 'Seite neu laden',
            pt: 'Recarregar página',
            fr: 'Recharger la page',
            zh: '重新加载页面。',
            ko: '페이지 새로 고침',
            ja: 'ページをリロード',
            th: 'โหลดหน้าใหม่',
            he: 'טען מחדש את הדף',
            zh_tw: '重新加载页面',
            fil: 'I-refresh ang pahina'
        },
        core_51: {
            ru: 'Спасибо',
            en: 'Thank you',
            tr: 'Teşekkür ederim',
            es: 'Gracias',
            ar: 'شكرا لك',
            de: 'Danke',
            pt: 'Obrigado',
            fr: 'Merci',
            zh: '谢谢',
            ko: '감사합니다',
            ja: 'ありがとうございます',
            th: 'ขอบคุณ',
            he: 'תודה',
            zh_tw: '谢谢',
            fil: 'Salamat po'
        },
        core_52: {
            ru: 'Скопировать код',
            en: 'Copy code',
            tr: 'Kodu kopyala',
            es: 'Copiar código',
            ar: 'نسخ الكود',
            de: 'Code kopieren',
            pt: 'Copiar código',
            fr: 'Copier le code',
            zh: '复制代码',
            ko: '코드 복사',
            ja: 'コードをコピーする',
            th: 'คัดลอกโค้ด',
            he: 'העתק קוד',
            zh_tw: '复制代码',
            fil: 'Kopyahin ang code'
        },
        core_53: {
            ru: 'Код успешно скопирован в буфер',
            en: 'Code has been successfully copied to the clipboard',
            tr: 'Kod başarıyla panoya kopyalandı',
            es: 'El código se ha copiado correctamente al portapapeles',
            ar: 'تم نسخ الكود بنجاح إلى الحافظة',
            de: 'Der Code wurde erfolgreich in die Zwischenablage kopiert',
            pt: 'Código foi copiado com sucesso para a área de transferência',
            fr: 'Le code a été copié avec succès dans le presse-papiers',
            zh: '密码已成功复制到剪贴板',
            ko: '코드가 클립보드에 성공적으로 복사되었습니다',
            ja: 'コードがクリップボードに正常にコピーされました',
            th: 'คัดลอกโค้ดสำเร็จแล้ว',
            he: 'הקוד הועתק בהצלחה ללוח',
            zh_tw: '代码已成功复制到剪贴板',
            fil: 'Matagumpay na nakopya ang code sa clipboard'
        },
        core_54: {
            ru: 'Код',
            en: 'Code',
            tr: 'Kod',
            es: 'Código',
            ar: 'كود',
            de: 'Code',
            pt: 'Código',
            fr: 'Code',
            zh: '密码',
            ko: '코드',
            ja: 'コード',
            th: 'โค้ด',
            he: 'קוד',
            zh_tw: '代码',
            fil: 'Code'
        },
        core_55: {
            ru: 'Неизвестно',
            en: 'Unknown',
            tr: 'Bilinmiyor',
            es: 'Desconocido',
            ar: 'غير معروف',
            de: 'Unbekannt',
            pt: 'Desconhecido',
            fr: 'Inconnu',
            zh: '未知',
            ko: '알 수 없음',
            ja: '不明',
            th: 'ไม่รู้จัก',
            he: 'לא ידוע',
            zh_tw: '未知',
            fil: 'Hindi kilala'
        },
        core_56: {
            ru: 'Тема',
            en: 'Theme',
            tr: 'Tema',
            es: 'Tema',
            ar: 'الموضوع',
            de: 'Thema',
            pt: 'Tema',
            fr: 'Thème',
            zh: '主题',
            ko: '테마',
            ja: 'テーマ',
            th: 'ธีม',
            he: 'ערכת נושא',
            zh_tw: '主题',
            fil: 'Tema'
        },
        core_57: {
            ru: 'Темная тема',
            en: 'Dark theme',
            tr: 'Karanlık tema',
            es: 'Tema oscuro',
            ar: 'المظهر الداكن',
            de: 'Dunkles Thema',
            pt: 'Tema escuro',
            fr: 'Thème sombre',
            zh: '暗主题',
            ko: '다크 테마',
            ja: 'ダークテーマ',
            th: 'ธีมมืด',
            he: 'נושא כהה',
            zh_tw: '黑暗主题',
            fil: 'Madilim na tema'
        },
        core_58: {
            ru: 'Требуется подтверждение',
            en: 'Confirmation required',
            tr: 'Onay gerekiyor',
            es: 'Se requiere confirmación',
            ar: 'مطلوب تأكيد',
            de: 'Bestätigung erforderlich',
            pt: 'Confirmação necessária',
            fr: 'Confirmation requise',
            zh: '需要确认',
            ko: '확인이 필요합니다',
            ja: '確認が必要です',
            th: 'ต้องการการยืนยัน',
            he: 'דרוש אישור',
            zh_tw: '需要确认',
            fil: 'Kailangan ng kumpirmasyon'
        },
        core_59: {
            ru: 'Вы уверены, что хотите удалить элемент?',
            en: 'Are you sure you want to delete this item?',
            tr: 'Bu öğeyi silmek istediğinizden emin misiniz?',
            es: '¿Estás seguro de que quieres eliminar este elemento?',
            ar: 'هل أنت متأكد أنك تريد حذف هذا العنصر؟',
            de: 'Sind Sie sicher, dass Sie diesen Artikel löschen möchten?',
            pt: 'Tem certeza de que deseja excluir este item?',
            fr: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
            zh: '您确定要删除此项目吗？',
            ko: '이 항목을 삭제하시겠습니까?',
            ja: 'このアイテムを削除してもよろしいですか？',
            th: 'คุณแน่ใจหรือว่าต้องการลบรายการนี้?',
            he: 'האם אתה בטוח שאתה רוצה למחוק את הפריט הזה?',
            zh_tw: '您确定要删除此项目吗？',
            fil: 'Sigurado ka bang gusto mong burahin ang item na ito?'
        },
        core_60: {
            ru: 'Все данные данного элемента будут удалены',
            en: 'All data related to this item will be deleted',
            tr: 'Bu öğeye ait tüm veriler silinecektir',
            es: 'Todos los datos relacionados con este elemento serán eliminados',
            ar: 'سيتم حذف جميع البيانات المتعلقة بهذا العنصر',
            de: 'Alle mit diesem Artikel verbundenen Daten werden gelöscht',
            pt: 'Todos os dados relacionados a este item serão excluídos',
            fr: 'Toutes les données relatives à cet élément seront supprimées',
            zh: '此项目中的所有数据将被删除',
            ko: '이 항목과 관련된 모든 데이터가 삭제됩니다',
            ja: 'このアイテムに関連するすべてのデータが削除されます',
            th: 'ข้อมูลทั้งหมดที่เกี่ยวข้องกับรายการนี้จะถูกลบทิ้ง',
            he: 'כל הנתונים הקשורים לפריט זה יימחקו.',
            zh_tw: '与此项目相关的所有数据都将被删除',
            fil: 'Ang lahat ng kaugnay na data sa item na ito ay mabubura'
        },
        core_61: {
            ru: 'Категория',
            en: 'Category',
            tr: 'Kategori',
            es: 'Categoría',
            ar: 'الفئة',
            de: 'Kategorie',
            pt: 'Categoria',
            fr: 'Catégorie',
            zh: '类别',
            ko: '카테고리',
            ja: 'カテゴリ',
            th: 'หมวดหมู่',
            he: 'קטגוריה',
            zh_tw: '类别',
            fil: 'Kategorya'
        },
        core_62: {
            ru: 'Все категории',
            en: 'All categories',
            tr: 'Tüm kategoriler',
            es: 'Todas las categorías',
            ar: 'جميع الفئات',
            de: 'Alle Kategorien',
            pt: 'Todas as categorias',
            fr: 'Toutes les catégories',
            zh: '所有类别',
            ko: '모든 카테고리',
            ja: 'すべてのカテゴリ',
            th: 'หมวดหมู่ทั้งหมด',
            he: 'כל הקטגוריות',
            zh_tw: '所有类别',
            fil: 'Lahat ng kategorya'
        },
        core_63: {
            ru: 'Станции',
            en: 'Stations',
            tr: 'İstasyonlar',
            es: 'Estaciones',
            ar: 'محطات',
            de: 'Stationen',
            pt: 'Estações',
            fr: 'Stations',
            zh: '站点',
            ko: '역',
            ja: '駅',
            th: 'สถานี',
            he: 'תחנות',
            zh_tw: '站点',
            fil: 'Mga Istasyon'
        },
        core_64: {
            ru: 'Стандарт',
            en: 'Standard',
            tr: 'Standart',
            es: 'Estándar',
            ar: 'معيار',
            de: 'Standard',
            pt: 'Padrão',
            fr: 'Standard',
            zh: '标准',
            ko: '표준',
            ja: '標準',
            th: 'มาตรฐาน',
            he: 'רגיל',
            zh_tw: '标准',
            fil: 'Standard'
        },
        core_65: {
            ru: 'Да',
            en: 'Yes',
            tr: 'Evet',
            es: 'Sí',
            ar: 'نعم',
            de: 'Ja',
            pt: 'Sim',
            fr: 'Oui',
            zh: '是',
            ko: '예',
            ja: 'はい',
            th: 'ใช่',
            he: 'כן',
            zh_tw: '是',
            fil: 'Oo'
        },
        core_66: {
            ru: 'Нет',
            en: 'No',
            tr: 'Hayır',
            es: 'No',
            ar: 'لا',
            de: 'Nein',
            pt: 'Não',
            fr: 'Non',
            zh: '否',
            ko: '아니요',
            ja: 'いいえ',
            th: 'ไม่',
            he: 'לא',
            zh_tw: '否',
            fil: 'Hindi'
        },
        core_67: (str: string) => {
            if (str === 'Ambulant') {
                return {
                    ru: 'Амбулаторный прием',
                    en: 'Outpatient appointment',
                    tr: 'Ayaktan tedavi randevusu',
                    es: 'Cita ambulatoria',
                    ar: 'موعد العيادة الخارجية',
                    de: 'Ambulante Termin',
                    pt: 'Consulta ambulatorial',
                    fr: 'Rendez-vous en consultation externe',
                    zh: '门诊预约',
                    ko: '외래 진료',
                    ja: '外来予約',
                    th: 'นัดหมายผู้ป่วยนอก',
                    he: 'פגישה חוץ בית חולים',
                    zh_tw: '门诊预约',
                    fil: 'Outpatient appointment'
                }
            } else if (str === 'Nursing') {
                return {
                    ru: 'Сестринское дело',
                    en: 'Nursing',
                    tr: 'Nursing',
                    es: 'Nursing',
                    ar: 'Nursing',
                    de: 'Nursing',
                    pt: 'Nursing',
                    fr: 'Nursing',
                    zh: 'Nursing',
                    ko: 'Nursing',
                    ja: 'Nursing',
                    th: 'Nursing',
                    he: 'Nursing',
                    zh_tw: 'Nursing',
                    fil: 'Nursing'
                }
            }
        },
        core_68: (email: string) => {
            return {
                ru: `На ${email} отправлены данные о новой учетной записи. Лицензия активирована`,
                en: `A new account has been created for ${email}. License activated.`,
                tr: `${email} için yeni bir hesap oluşturuldu. Lisans etkinleştirildi.`,
                es: `Se ha creado una nueva cuenta para ${email}. Licencia activada.`,
                ar: `تم إنشاء حساب جديد لـ ${email}. تم تنشيط الترخيص.`,
                de: `Ein neues Konto wurde für ${email} erstellt. Lizenz aktiviert.`,
                pt: `Uma nova conta foi criada para ${email}. Licença ativada.`,
                fr: `Un nouveau compte a été créé pour ${email}. Licence activée.`,
                zh: `新帐户数据已发送至${email}。许可证已激活`,
                ko: `${email}을 위한 새 계정이 생성되었습니다. 라이선스가 활성화되었습니다.`,
                ja: `${email} 用の新しいアカウントが作成されました。ライセンスが有効になりました。`,
                th: `บัญชีใหม่ถูกสร้างสำหรับ ${email} การใช้งานได้รับการเปิดใช้งาน`,
                he: `חשבון חדש נוצר עבור ${email}. הרישיון הופעל`,
                zh_tw: `为${email}创建了新账户。许可证已激活。`,
                fil: `Isang bagong account ang nilikha para kay ${email}. Lisensya na-activate`
            }
        },
        core_69: {
            ru: 'Загрузить',
            en: 'Upload',
            tr: 'Yükle',
            es: 'Subir',
            ar: 'تحميل',
            de: 'Hochladen',
            pt: 'Upload',
            fr: 'Télécharger',
            zh: '上传',
            ko: '업로드',
            ja: 'アップロード',
            th: 'อัปโหลด',
            he: 'העלה',
            zh_tw: '上传',
            fil: 'I-upload'
        },
        core_70: {
            ru: 'Загрузка файла',
            en: 'File upload',
            tr: 'Dosya yükle',
            es: 'Subir archivo',
            ar: 'تحميل الملف',
            de: 'Datei hochladen',
            pt: 'Carregar arquivo',
            fr: 'Téléchargement de fichier',
            zh: '上传文件',
            ko: '파일 업로드',
            ja: 'ファイルのアップロード',
            th: 'อัปโหลดไฟล์',
            he: 'העלאת קובץ',
            zh_tw: '文件上传',
            fil: 'I-upload ang file'
        },
        core_71: {
            ru: 'Скачать результат',
            en: 'Download result',
            tr: 'Sonucu indir',
            es: 'Descargar resultado',
            ar: 'تحميل النتيجة',
            de: 'Ergebnis herunterladen',
            pt: 'Baixar resultado',
            fr: 'Télécharger le résultat',
            zh: '下载结果',
            ko: '결과 다운로드',
            ja: '結果のダウンロード',
            th: 'ดาวน์โหลดผลลัพธ์',
            he: 'הורדת תוצאה',
            zh_tw: '下载结果',
            fil: 'I-download ang resulta'
        },
        core_72: {
            ru: 'Осмотр',
            en: 'Examination',
            tr: 'Muayene',
            es: 'Examen',
            ar: 'فحص',
            de: 'Untersuchung',
            pt: 'Exame',
            fr: 'Examen',
            zh: '检查',
            ko: '검사',
            ja: '検査',
            th: 'การตรวจ',
            he: 'בדיקה',
            zh_tw: '检查',
            fil: 'Pagsusuri'
        },
        core_73: {
            ru: 'Успеваемость',
            en: 'Academic performance',
            tr: 'Akademik performans',
            es: 'Rendimiento académico',
            ar: 'الأداء الأكاديمي',
            de: 'Akademische Leistung',
            pt: 'Desempenho acadêmico',
            fr: 'Performance académique',
            zh: '成绩',
            ko: '학업 성적',
            ja: '学業成績',
            th: 'ผลสอบ',
            he: 'ביצועים אקדמאיים',
            zh_tw: '学术表现',
            fil: 'Akademikong pagganap'
        },
        core_74: {
            ru: 'Диапазон баллов',
            en: 'Score range',
            tr: 'Puan aralığı',
            es: 'Rango de puntuación',
            ar: 'نطاق الدرجات',
            de: 'Punktbereich',
            pt: 'Faixa de pontuação',
            fr: 'Plage de scores',
            zh: '分数范围',
            ko: '점수 범위',
            ja: 'スコアの範囲',
            th: 'ช่วงคะแนน',
            he: 'טווח הניקוד',
            zh_tw: '分数范围',
            fil: 'Saklaw ng marka'
        },
        core_75: {
            ru: 'Файл Excel должен иметь следующую структуру:',
            en: 'File Excel should have the following structure:',
            tr: 'Excel dosyası aşağıdaki yapıya sahip olmalıdır:',
            es: 'El archivo de Excel debe tener la siguiente estructura:',
            ar: 'يجب أن يكون ملف Excel بالهيكل التالي:',
            de: 'Excel-Datei sollte folgende Struktur haben:',
            pt: 'O arquivo Excel deve ter a seguinte estrutura:',
            fr: 'Le fichier Excel doit avoir la structure suivante:',
            zh: 'Excel文件应具有以下结构：',
            ko: 'Excel 파일은 다음과 같은 구조를 가져야합니다 :',
            ja: 'Excelファイルは次の構造を持つ必要があります：',
            th: 'ไฟล์ Excel ควรมีโครงสร้างดังต่อไปนี้:',
            he: 'קובץ Excel צריך להכיל את המבנה הבא:',
            zh_tw: 'Excel文件应具有以下结构:',
            fil: 'Ang file Excel ay dapat magkaroon ng sumusunod na istraktura:'
        },
        core_77: {
            ru: 'Выберете формат файла',
            en: 'Choose file format',
            tr: 'Dosya formatını seçin',
            es: 'Elija el formato del archivo',
            ar: 'اختر تنسيق الملف',
            de: 'Wählen Sie das Dateiformat',
            pt: 'Escolha o formato do arquivo',
            fr: 'Choisissez le format du fichier',
            zh: '选择文件格式',
            ko: '파일 형식 선택',
            ja: 'ファイル形式を選択してください',
            th: 'เลือกรูปแบบไฟล์',
            he: 'בחר פורמט הקובץ',
            zh_tw: '选择文件格式',
            fil: 'Pumili ng format ng file'
        },
        core_78: {
            ru: 'Время',
            en: 'Time',
            tr: 'Zaman',
            es: 'Tiempo',
            ar: 'الوقت',
            de: 'Zeit',
            pt: 'Tempo',
            fr: 'Temps',
            zh: '时间',
            ko: '시간',
            ja: '時間',
            th: 'เวลา',
            he: 'זמן',
            zh_tw: '时间',
            fil: 'Oras'
        },
        core_79: {
            ru: 'Пакет',
            en: 'Package',
            tr: 'Paket',
            es: 'Paquete',
            ar: 'حزمة',
            de: 'Paket',
            pt: 'Pacote',
            fr: 'Forfait',
            zh: '套餐',
            ko: '패키지',
            ja: 'パッケージ',
            th: 'แพ็คเกจ',
            he: 'חבילה',
            zh_tw: '套餐',
            fil: 'Package'
        },
        core_80: {
            ru: 'Генеральная лицензия',
            en: 'General license',
            tr: 'Genel lisans',
            es: 'Licencia general',
            ar: 'ترخيص عام',
            de: 'Allgemeine Lizenz',
            pt: 'Licença geral',
            fr: 'Licence générale',
            zh: '通用许可证',
            ko: '일반 라이센스',
            ja: '一般ライセンス',
            th: 'ใบอนุญาตทั่วไป',
            he: 'רישיון כללי',
            zh_tw: '普通许可证',
            fil: 'Pangkalahatang lisensya'
        },
        core_81: {
            ru: 'Неограниченная генеральная лицензия',
            en: 'Unlimited general license',
            tr: 'Sınırsız genel lisans',
            es: 'Licencia general ilimitada',
            ar: 'ترخيص عام غير محدود',
            de: 'Unbegrenzte allgemeine Lizenz',
            pt: 'Licença geral ilimitada',
            fr: 'Licence générale illimitée',
            zh: '无限通用许可证',
            ko: '무제한 일반 라이센스',
            ja: '無制限の一般ライセンス',
            th: 'ใบอนุญาตทั่วไปไม่จำกัด',
            he: 'רישיון כללי ללא הגבלה',
            zh_tw: '无限制的普通许可证',
            fil: 'Walang hanggang pangkalahatang lisensya'
        },
        core_82: {
            ru: 'Ограниченное кол-во лицензий',
            en: 'Limited number of licenses',
            tr: 'Sınırlı sayıda lisans',
            es: 'Número limitado de licencias',
            ar: 'عدد محدود من التراخيص',
            de: 'Begrenzte Anzahl von Lizenzen',
            pt: 'Número limitado de licenças',
            fr: 'Nombre limité de licences',
            zh: '有限数量的许可证',
            ko: '제한된 라이센스 수',
            ja: '制限付きのライセンス数',
            th: 'จำนวนจำกัดของใบอนุญาต',
            he: 'מספר מוגבל של רישיונות',
            zh_tw: '有限数量的许可证',
            fil: 'Limitadong bilang ng mga lisensya'
        },
        core_83: {
            ru: 'Лимит активации',
            en: 'Activation limit',
            tr: 'Aktivasyon limiti',
            es: 'Límite de activación',
            ar: 'حد التفعيل',
            de: 'Aktivierungslimit',
            pt: 'Limite de ativação',
            fr: "Limite d'activation",
            zh: '激活限制',
            ko: '활성화 제한',
            ja: 'アクティベーション制限',
            th: 'ขีด จำกัด การเปิดใช้งาน',
            he: 'הגבלת הפעלה',
            zh_tw: '激活限制',
            fil: 'Limitasyon sa pag-activate'
        },
        core_84: {
            ru: 'Срок действия',
            en: 'Validity period',
            tr: 'Geçerlilik süresi',
            es: 'Período de validez',
            ar: 'فترة الصلاحية',
            de: 'Gültigkeitsdauer',
            pt: 'Período de validade',
            fr: 'Période de validité',
            zh: '有效期',
            ko: '유효 기간',
            ja: '有効期間',
            th: 'ระยะเวลาที่ใช้ได้',
            he: 'תקופת תוקפו',
            zh_tw: '有效期',
            fil: 'Tagal ng bisa'
        },
        core_85: {
            ru: 'Для активации пакета лицензий нажмите кнопку',
            en: 'To activate the license package, press the button',
            tr: 'Lisans paketini etkinleştirmek için düğmeye basın',
            es: 'Para activar el paquete de licencias, presione el botón',
            ar: 'لتفعيل حزمة التراخيص ، اضغط على الزر',
            de: 'Um das Lizenzpaket zu aktivieren, drücken Sie die Taste',
            pt: 'Para ativar o pacote de licenças, pressione o botão',
            fr: 'Pour activer le package de licences, appuyez sur le bouton',
            zh: '要激活许可证包，请按下按钮',
            ko: '라이센스 패키지를 활성화하려면 버튼을 누르세요',
            ja: 'ライセンスパッケージをアクティベートするには、ボタンを押してください',
            th: 'หากต้องการเปิดใช้งานแพ็คเกจใบอนุญาต ให้กดปุ่ม',
            he: 'להפעלת חבילת הרישיון, לחץ על הכפתור',
            zh_tw: '要激活许可证包，请按下面的按钮',
            fil: 'Upang i-activate ang lisensya package, pindutin ang button'
        },
        core_86: {
            ru: 'Активировать',
            en: 'Activate',
            tr: 'Etkinleştir',
            es: 'Activar',
            ar: 'تفعيل',
            de: 'Aktivieren',
            pt: 'Ativar',
            fr: 'Activer',
            zh: '激活',
            ko: '활성화',
            ja: 'アクティベート',
            th: 'เปิดใช้งาน',
            he: 'הפעל',
            zh_tw: '激活',
            fil: 'I-activate'
        },
        core_87: {
            ru: 'Скачать',
            en: 'Download',
            tr: 'İndir',
            es: 'Descargar',
            ar: 'تحميل',
            de: 'Herunterladen',
            pt: 'Baixar',
            fr: 'Télécharger',
            zh: '下载',
            ko: '다운로드',
            ja: 'ダウンロード',
            th: 'ดาวน์โหลด',
            he: 'הורד',
            zh_tw: '下载',
            fil: 'I-download'
        },
        core_88: {
            ru: 'Отправить почтой',
            en: 'Send by mail',
            tr: 'Postayla gönder',
            es: 'Enviar por correo electrónico',
            ar: 'إرسال بالبريد الإلكتروني',
            de: 'Per E-Mail senden',
            pt: 'Enviar por e-mail',
            fr: 'Envoyer par courrier électronique',
            zh: '通过电子邮件发送',
            ko: '이메일로 보내기',
            ja: 'メールで送信',
            th: 'ส่งทางไปรษณีย์',
            he: 'שלח באימייל',
            zh_tw: '邮件发送',
            fil: 'Ipadala sa pamamagitan ng email'
        },
        core_89: {
            ru: 'Дата активации',
            en: 'Activation date',
            tr: 'Aktivasyon tarihi',
            es: 'Fecha de activación',
            ar: 'تاريخ التفعيل',
            de: 'Aktivierungsdatum',
            pt: 'Data de ativação',
            fr: "Date d'activation",
            zh: '激活日期',
            ko: '활성화 날짜',
            ja: 'アクティベーション日',
            th: 'วันที่เปิดใช้งาน',
            he: 'תאריך הפעלה',
            zh_tw: '激活日期',
            fil: 'Petsa ng pag-activate'
        },
        core_90: {
            ru: 'Сценарий',
            en: 'Script',
            tr: 'Senaryo',
            es: 'Guión',
            ar: 'سيناريو',
            de: 'Skript',
            pt: 'Roteiro',
            fr: 'Scénario',
            zh: '剧本',
            ko: '시나리오',
            ja: 'シナリオ',
            th: 'สคริปต์',
            he: 'תסריט',
            zh_tw: '脚本',
            fil: 'Script'
        },
        core_91: {
            ru: 'Без лицензии',
            en: 'Without license',
            tr: 'Lisanssız',
            es: 'Sin licencia',
            ar: 'بدون ترخيص',
            de: 'Ohne Lizenz',
            pt: 'Sem licença',
            fr: 'Sans licence',
            zh: '无许可证',
            ko: '라이선스 없음',
            ja: 'ライセンスなし',
            th: 'ไม่มีใบอนุญาต',
            he: 'בלי רישיון',
            zh_tw: '没有许可证',
            fil: 'Walang lisensya'
        },
        core_92: {
            ru: 'Десктоп',
            en: 'Desktop',
            tr: 'Masaüstü',
            es: 'Escritorio',
            ar: 'سطح المكتب',
            de: 'Desktop',
            pt: 'Área de trabalho',
            fr: 'Bureau',
            zh: '桌面',
            ko: '데스크탑',
            ja: 'デスクトップ',
            th: 'เดสก์ท็อป',
            he: 'שולחן עבודה',
            zh_tw: '桌面',
            fil: 'Desktop'
        },
        core_93: {
            ru: 'VR-очки',
            en: 'VR headset',
            tr: 'VR gözlükleri',
            es: 'Gafas de realidad virtual (VR)',
            ar: 'نظارات الواقع الافتراضي (VR)',
            de: 'VR-Brille',
            pt: 'Óculos de realidade virtual (VR)',
            fr: 'Casque de réalité virtuelle (VR)',
            zh: 'VR眼镜',
            ko: 'VR 안경',
            ja: 'VRゴーグル',
            th: 'แว่น VR',
            he: 'כובע מציאות מדומה',
            zh_tw: 'VR耳机',
            fil: 'VR headset'
        },
        core_94: {
            ru: 'Календарь',
            en: 'Calendar',
            tr: 'Takvim',
            es: 'Calendario',
            ar: 'التقويم',
            de: 'Kalender',
            pt: 'Calendário',
            fr: 'Calendrier',
            zh: '日历',
            ko: '캘린더',
            ja: 'カレンダー',
            th: 'ปฏิทิน',
            he: 'לוח שנה',
            zh_tw: '日历',
            fil: 'Kalendaryo'
        },
        core_95: {
            ru: 'Другое',
            en: 'Other',
            tr: 'Diğer',
            es: 'Otro',
            ar: 'آخر',
            de: 'Andere',
            pt: 'Outro',
            fr: 'Autre',
            zh: '其他',
            ko: '기타',
            ja: 'その他',
            th: 'อื่น ๆ',
            he: 'אחר',
            zh_tw: '其他',
            fil: 'Iba pa'
        },
        core_96: {
            ru: 'Заблокировать',
            en: 'Lock',
            tr: 'Kilitle',
            es: 'Bloquear',
            ar: 'قفل',
            de: 'Sperren',
            pt: 'Bloquear',
            fr: 'Verrouiller',
            zh: '锁定',
            ko: '잠금',
            ja: 'ロックする',
            th: 'ล็อก',
            he: 'נעל',
            zh_tw: '锁定',
            fil: 'I-lock'
        },
        core_97: {
            ru: 'Разблокировать',
            en: 'Unlock',
            tr: 'Kilidi aç',
            es: 'Desbloquear',
            ar: 'فتح القفل',
            de: 'Entsperren',
            pt: 'Desbloquear',
            fr: 'Déverrouiller',
            zh: '解锁',
            ko: '잠금 해제',
            ja: 'ロック解除する',
            th: 'ปลดล็อก',
            he: 'שחרר',
            zh_tw: '解锁',
            fil: 'I-unlock'
        },
        core_98: {
            ru: 'Отправить код',
            en: 'Send code',
            tr: 'Kodu gönder',
            es: 'Enviar código',
            ar: 'إرسال الرمز',
            de: 'Code senden',
            pt: 'Enviar código',
            fr: 'Envoyer le code',
            zh: '发送代码',
            ko: '코드 보내기',
            ja: 'コードを送信する',
            th: 'ส่งรหัส',
            he: 'שלח קוד',
            zh_tw: '发送代码',
            fil: 'Ipadala ang code'
        },
        core_99: {
            ru: 'У вас нет доступа к пользовательской лицензии',
            en: 'You do not have access to a user license',
            tr: 'Bir kullanıcı lisansına erişiminiz yok.',
            es: 'No tienes acceso a una licencia de usuario.',
            ar: 'ليس لديك صلاحية الوصول إلى ترخيص المستخدم',
            de: 'Sie haben keinen Zugriff auf eine Benutzerlizenz.',
            pt: 'Você não tem acesso a uma licença de usuário.',
            fr: "Vous n'avez pas accès à une licence utilisateur.",
            zh: '您没有访问用户许可的权限。',
            ko: '사용자 라이선스에 액세스할 수 없습니다.',
            ja: 'ユーザーライセンスにアクセスできません。',
            th: 'คุณไม่สามารถเข้าถึงใบอนุญาตผู้ใช้ได้',
            he: 'אין לך גישה לרישיון משתמש',
            zh_tw: '您没有访问用户许可证的权限',
            fil: 'Wala kang access sa user license'
        },
        auth_1: {
            ru: 'Почта',
            en: 'Email',
            tr: 'E-posta',
            es: 'Correo electrónico',
            ar: 'البريد الإلكتروني',
            de: 'Email',
            pt: 'Email',
            fr: 'Email',
            zh: '邮件',
            ko: '이메일',
            ja: 'メール',
            th: 'อีเมล',
            he: 'אימייל',
            zh_tw: '电子邮件',
            fil: 'Email'
        },
        auth_2: {
            ru: 'Пароль',
            en: 'Password',
            tr: 'Şifre',
            es: 'Contraseña',
            ar: 'كلمة السر',
            de: 'Passwort',
            pt: 'Senha',
            fr: 'Mot de passe',
            zh: '密码',
            ko: '비밀번호',
            ja: 'パスワード',
            th: 'รหัสผ่าน',
            he: 'סיסמה',
            zh_tw: '密码',
            fil: 'Password'
        },
        auth_3: {
            ru: 'Войти',
            en: 'Log in',
            tr: 'Giriş yap',
            es: 'Iniciar sesión',
            ar: 'تسجيل الدخول',
            de: 'Anmelden',
            pt: 'Entrar',
            fr: 'Se connecter',
            zh: '登录',
            ko: '로그인',
            ja: 'ログイン',
            th: 'เข้าสู่ระบบ',
            he: 'התחבר',
            zh_tw: '登录',
            fil: 'Mag-log in'
        },
        auth_4: {
            ru: 'Выйти',
            en: 'Log out',
            tr: 'Çıkış yap',
            es: 'Cerrar sesión',
            ar: 'تسجيل الخروج',
            de: 'Abmelden',
            pt: 'Sair',
            fr: 'Se déconnecter',
            zh: '退出',
            ko: '로그아웃',
            ja: 'ログアウト',
            th: 'ออกจากระบบ',
            he: 'התנתק',
            zh_tw: '退出登录',
            fil: 'Mag-log out'
        },
        auth_5: {
            ru: 'Восстановить пароль',
            en: 'Reset password',
            tr: 'Şifreyi sıfırla',
            es: 'Restablecer contraseña',
            ar: 'إعادة تعيين كلمة المرور',
            de: 'Passwort zurücksetzen',
            pt: 'Redefinir senha',
            fr: 'Réinitialiser le mot de passe',
            zh: '重置密码',
            ko: '비밀번호 재설정',
            ja: 'パスワードをリセット',
            th: 'รีเซ็ตรหัสผ่าน',
            he: 'אפס סיסמה',
            zh_tw: '重置密码',
            fil: 'I-reset ang password'
        },
        auth_6: {
            ru: 'Восстановить',
            en: 'Restore',
            tr: 'Geri yükle',
            es: 'Restaurar',
            ar: 'استعادة',
            de: 'Wiederherstellen',
            pt: 'Restaurar',
            fr: 'Restaurer',
            zh: '恢复',
            ko: '복원',
            ja: '復元',
            th: 'เรียกคืน',
            he: 'שחזר',
            zh_tw: '恢复',
            fil: 'I-restore'
        },
        auth_7: {
            ru: 'К авторизации',
            en: 'Back to login',
            tr: 'Girişe geri dön',
            es: 'Volver al inicio de sesión',
            ar: 'العودة لتسجيل الدخول',
            de: 'Zurück zur Anmeldung',
            pt: 'Voltar para login',
            fr: 'Retour à la connexion',
            zh: '到登录页面',
            ko: '로그인으로 돌아가기',
            ja: 'ログイン画面に戻る',
            th: 'กลับไปที่หน้าเข้าสู่ระบบ',
            he: 'חזור להתחברות',
            zh_tw: '返回登录页面',
            fil: 'Bumalik sa pag-login'
        },
        auth_8: {
            ru: 'На ваш электронный адрес, отправлена инструкция по сбросу пароля. Возможно, оно попало в спам.',
            en: 'Instructions for resetting your password have been sent to your email. Please check your spam folder as well',
            tr: 'Şifrenizi sıfırlama talimatları e-postanıza gönderildi. Lütfen spam klasörünüzü de kontrol edin',
            es: 'Las instrucciones para restablecer su contraseña se han enviado a su correo electrónico. Por favor, también revisa tu carpeta de spam',
            ar: 'تم إرسال تعليمات إعادة تعيين كلمة المرور إلى بريدك الإلكتروني. يرجى التحقق من ملف البريد العشوائي أيضًا.',
            de: 'Anweisungen zum Zurücksetzen Ihres Passworts wurden an Ihre E-Mail gesendet. Bitte überprüfen Sie auch Ihren Spam-Ordner',
            pt: 'As instruções para redefinir sua senha foram enviadas para o seu email. Por favor, verifique também a sua pasta de spam',
            fr: 'Les instructions pour réinitialiser votre mot de passe ont été envoyées à votre adresse e-mail. Veuillez vérifier votre dossier spam aussi',
            zh: '重置密码说明已发送到您的电子邮件地址。可能会进入垃圾邮件。',
            ko: '비밀번호 재설정 안내가 이메일로 전송되었습니다. 스팸 폴더도 확인해주세요.',
            ja: 'パスワードのリセット手順がメールで送信されました。迷惑メールフォルダもご確認ください。',
            th: 'คำแนะนำในการรีเซ็ตรหัสผ่านได้ถูกส่งไปยังอีเมลของคุณ โปรดตรวจสอบโฟลเดอร์สแปมของคุณด้วย',
            he: 'הוראות לאיפוס הסיסמה נשלחו לאימייל שלך. אנא בדוק גם את תיקיית הספאם שלך',
            zh_tw: '已将重置密码的说明发送到您的邮箱。请同时检查您的垃圾邮件文件夹',
            fil: 'Ang mga tagubilin para sa pag-reset ng iyong password ay ipinadala sa iyong email. Mangyaring tingnan din ang iyong spam folder.'
        },
        auth_9: {
            ru: 'Успешно!',
            en: 'Success!',
            tr: 'Başarılı!',
            es: '¡Éxito!',
            ar: 'نجاح!',
            de: 'Erfolg!',
            pt: 'Sucesso!',
            fr: 'Succès!',
            zh: '成功！',
            ko: '성공!',
            ja: '成功！',
            th: 'สำเร็จ!',
            he: 'הצלחה!',
            zh_tw: '成功！',
            fil: 'Tagumpay!'
        },
        auth_10: {
            ru: 'Запомнить данные для входа',
            en: 'Remember login details',
            tr: 'Giriş detaylarını hatırla',
            es: 'Recordar detalles de inicio de sesión',
            ar: 'تذكر تفاصيل تسجيل الدخول',
            de: 'Anmeldedetails merken',
            pt: 'Lembrar detalhes de login',
            fr: 'Se souvenir des détails de connexion',
            zh: '记住登录信息。',
            ko: '로그인 정보 저장',
            ja: 'ログイン情報を記憶する',
            th: 'จำรายละเอียดการเข้าสู่ระบบ',
            he: 'זכור פרטי ההתחברות',
            zh_tw: '记住登录详情',
            fil: 'Tandaan ang mga detalye ng pag-login'
        },
        auth_11: {
            ru: 'Показать пароль',
            en: 'Show password',
            tr: 'Şifreyi göster',
            es: 'Mostrar contraseña',
            ar: 'إظهار كلمة المرور',
            de: 'Passwort anzeigen',
            pt: 'Mostrar senha',
            fr: 'Afficher le mot de passe',
            zh: '显示密码',
            ko: '비밀번호 표시',
            ja: 'パスワードを表示する',
            th: 'แสดงรหัสผ่าน',
            he: 'הצג סיסמה',
            zh_tw: '显示密码',
            fil: 'Ipakita ang password'
        },
        license_1: {
            ru: 'Фамилия',
            en: 'Last name',
            tr: 'Soyadı',
            es: 'Apellido',
            ar: 'اسم العائلة',
            de: 'Nachname',
            pt: 'Sobrenome',
            fr: 'Nom de famille',
            zh: '姓',
            ko: '성',
            ja: '姓',
            th: 'นามสกุล',
            he: 'שם משפחה',
            zh_tw: '姓',
            fil: 'Apelyido'
        },
        license_2: {
            ru: 'Имя',
            en: 'First name',
            tr: 'Adı',
            es: 'Nombre',
            ar: 'الاسم الأول',
            de: 'Vorname',
            pt: 'Primeiro nome',
            fr: 'Prénom',
            zh: '名',
            ko: '이름',
            ja: '名',
            th: 'ชื่อ',
            he: 'שם פרטי',
            zh_tw: '名',
            fil: 'Pangalan'
        },
        license_3: {
            ru: 'Отчество',
            en: 'Middle name',
            tr: 'İkinci adı',
            es: 'Segundo nombre',
            ar: 'الاسم الأوسط',
            de: 'Zweiter Vorname',
            pt: 'Nome do meio',
            fr: 'Deuxième prénom',
            zh: '中间名',
            ko: '중간 이름',
            ja: 'ミドルネーム',
            th: 'ชื่อกลาง',
            he: 'שם אמצעי',
            zh_tw: '中间名',
            fil: 'Gitnang pangalan'
        },
        license_4: {
            ru: 'ID (VR устройство)',
            en: 'ID (VR device)',
            tr: 'Kimlik (VR cihaz)',
            es: 'ID (dispositivo VR)',
            ar: 'معرف (جهاز الواقع الافتراضي)',
            de: 'ID (VR-Gerät)',
            pt: 'ID (dispositivo VR)',
            fr: 'ID (appareil VR)',
            zh: 'ID（VR设备）',
            ko: 'ID (VR 장치)',
            ja: 'ID（VRデバイス）',
            th: 'ID (อุปกรณ์ VR)',
            he: 'מזהה (מכשיר VR)',
            zh_tw: 'ID（VR设备）',
            fil: 'ID (VR device)'
        },
        license_5: {
            ru: 'Осталось неиспользованных лицензий',
            en: 'Unused licenses remaining',
            tr: 'Kullanılmayan lisans kaldı',
            es: 'Licencias no utilizadas restantes',
            ar: 'التراخيص غير المستخدمة المتبقية',
            de: 'Verbleibende ungenutzte Lizenzen',
            pt: 'Licenças não utilizadas restantes',
            fr: 'Licences inutilisées restantes',
            zh: '剩余未使用的许可证',
            ko: '사용되지 않은 라이선스가 남아 있습니다',
            ja: '未使用のライセンスが残っています',
            th: 'ใบอนุญาตที่ยังไม่ได้ใช้งาน',
            he: 'רישיונות שלא הופעלו נותרו',
            zh_tw: '剩余的未使用许可证',
            fil: 'Natitirang hindi nagamit na mga lisensya'
        },
        exam_1: {
            ru: 'Запланированные экзамены',
            en: 'Scheduled exams',
            tr: 'Planlanan sınavlar',
            es: 'Exámenes programados',
            ar: 'الامتحانات المقررة',
            de: 'Geplante Prüfungen',
            pt: 'Exames agendados',
            fr: 'Examens programmés',
            zh: '已计划的考试',
            ko: '예정된 검사',
            ja: '予定された検査',
            th: 'ตารางการสอบที่กำหนด',
            he: 'בחינות מתוכננות',
            zh_tw: '计划的考试',
            fil: 'Naka-iskedyul na mga pagsusulit'
        },
        exam_2: {
            ru: 'Проведенные экзамены',
            en: 'Completed exams',
            tr: 'Tamamlanan sınavlar',
            es: 'Exámenes completados',
            ar: 'الامتحانات المنتهية',
            de: 'Abgeschlossene Prüfungen',
            pt: 'Exames concluídos',
            fr: 'Examens terminés',
            zh: '已完成的考试',
            ko: '완료된 검사',
            ja: '完了した検査',
            th: 'การสอบที่เสร็จสมบูรณ์',
            he: 'בחינות שהושלמו',
            zh_tw: '已完成的考试',
            fil: 'Natapos na mga pagsusulit'
        },
        exam_3: {
            ru: 'ФИО:',
            en: 'Full name:',
            tr: 'Tam adı:',
            es: 'Nombre completo:',
            ar: 'الاسم الكامل:',
            de: 'Vollständiger Name:',
            pt: 'Nome completo:',
            fr: 'Nom complet :',
            zh: '姓名：',
            ko: '전체 이름:',
            ja: 'フルネーム：',
            th: 'ชื่อเต็ม:',
            he: 'שם מלא:',
            zh_tw: '全名：',
            fil: 'Buong pangalan:'
        },
        exam_4: {
            ru: 'Кейс:',
            en: 'Case:',
            tr: 'Vaka:',
            es: 'Caso:',
            ar: 'حالة:',
            de: 'Fall:',
            pt: 'Caso:',
            fr: 'Cas :',
            zh: '案例：',
            ko: '사례:',
            ja: 'ケース：',
            th: 'เคส:',
            he: 'מקרה:',
            zh_tw: '案例：',
            fil: 'Kaso:'
        },
        exam_5: {
            ru: 'ЭКЗАМЕН',
            en: 'EXAM',
            tr: 'SINAV',
            es: 'EXAMEN',
            ar: 'امتحان',
            de: 'PRÜFUNG',
            pt: 'EXAME',
            fr: 'EXAMEN',
            zh: '考试',
            ko: '시험',
            ja: '試験',
            th: 'การสอบ',
            he: 'בחינה',
            zh_tw: '考试',
            fil: 'PAGSUSULIT'
        }
    },
    errors: {
        core_1: {
            ru: 'Ошибка',
            en: 'Error',
            tr: 'Hata',
            es: 'Error',
            ar: 'خطأ',
            de: 'Fehler',
            pt: 'Erro',
            fr: 'Erreur',
            zh: '错误',
            ko: '오류',
            ja: 'エラー',
            th: 'ข้อผิดพลาด',
            he: 'שגיאה',
            zh_tw: '错误',
            fil: 'Error'
        },
        core_2: (min: number) => {
            return ({
                ru: `Лицензии могут быть в промежутке от ${min} до 1000000`,
                en: `Licenses can be between ${min} and 1000000.`,
                tr: `Lisanslar ${min} ile 1000000 arasında olabilir.`,
                es: `Las licencias pueden ser entre ${min} y 1000000.`,
                ar: `التراخيص يمكن أن تكون بين ${min} و 1000000.`,
                de: `Lizenzen können zwischen ${min} und 1000000 liegen.`,
                pt: `Licenças podem ser entre ${min} e 1000000.`,
                fr: `Les licences peuvent être entre ${min} et 1000000.`,
                zh: `许可证可以在${min}到1000000之间`,
                ko: `라이선스는 ${min}에서 1000000 사이여야 합니다.`,
                ja: `ライセンスは${min}から1000000の間で設定できます。`,
                th: `ใบอนุญาตสามารถอยู่ระหว่าง ${min} และ 1000000`,
                he: `הרישיון יכול להיות בין ${min} ל-1000000`,
                zh_tw: `许可证可以在${min}和1000000之间`,
                fil: `Ang mga lisensya ay maaaring maging mula ${min} hanggang 1000`
            })
        },
        core_3: {
            ru: 'Превышен лимит лицензий',
            en: 'License limit exceeded',
            tr: 'Lisans sınırı aşıldı',
            es: 'Se ha excedido el límite de licencias',
            ar: 'تجاوز حد الترخيص',
            de: 'Lizenzlimit überschritten',
            pt: 'Limite de licença excedido',
            fr: 'Limite de licence dépassée',
            zh: '超过许可证限制',
            ko: '라이선스 제한을 초과했습니다',
            ja: 'ライセンス制限を超えました。',
            th: 'ใบอนุญาตเกินจำนวน',
            he: 'חריגה ממכסת הרישוי',
            zh_tw: '超过了许可证限制',
            fil: 'Lumampas sa limitasyon ng lisensya'
        },
        core_4: {
            ru: 'Данные не заполнены или заполнены неверно',
            en: 'Data is not filled in or is filled in incorrectly',
            tr: 'Veriler doldurulmamış veya yanlış doldurulmuş',
            es: 'Los datos no están llenos o están llenos incorrectamente',
            ar: 'البيانات غير مملوءة أو مملوءة بشكل غير صحيح',
            de: 'Die Daten sind nicht oder falsch ausgefüllt',
            pt: 'Os dados não estão preenchidos ou estão preenchidos incorretamente',
            fr: 'Les données ne sont pas remplies ou sont remplies de manière incorrecte',
            zh: '数据未填写或填写不正确。',
            ko: '데이터가 입력되지 않았거나 잘못 입력되었습니다',
            ja: 'データが入力されていないか、正しく入力されていません。',
            th: 'เกินขีดจำกัดของข้อมูลที่ไม่ได้ระบุหรือกรอกข้อมูลไม่ถูกต้อง',
            he: 'הנתונים לא מולאו או מולאו באופן שגוי',
            zh_tw: '数据未填写或填写不正确',
            fil: 'Ang data ay hindi naisalin o mali ang pagkakapunan'
        },
        core_5: {
            ru: 'Дата и время не может быть раньше текущей!',
            en: 'Date and time cannot be earlier than the current time!',
            tr: 'Tarih ve saat, şimdiki zamandan daha erken olamaz!',
            es: '¡La fecha y la hora no pueden ser anteriores a la hora actual!',
            ar: 'التاريخ والوقت لا يمكن أن يكونا قبل الوقت الحالي!',
            de: 'Datum und Uhrzeit dürfen nicht früher als die aktuelle Zeit sein!',
            pt: 'Data e hora não podem ser anteriores ao tempo atual!',
            fr: "La date et l'heure ne peuvent pas être antérieures à l'heure actuelle!",
            zh: '日期和时间不能早于当前时间！',
            ko: '날짜와 시간은 현재 시간보다 이전일 수 없습니다!',
            ja: '日付と時刻は現在の時刻よりも前にすることはできません！',
            th: 'วันที่และเวลาไม่สามารถเป็นก่อนเวลาปัจจุบันได้!',
            he: 'התאריך והשעה לא יכולים להיות מוקדמים מהזמן הנוכחי!',
            zh_tw: '日期和时间不能早于当前时间！',
            fil: 'Ang petsa at oras ay hindi maaaring mas maaga kaysa sa kasalukuyang oras!'
        },
        core_6: {
            ru: 'Произошла ошибка',
            en: 'An error occurred',
            tr: 'Bir hata oluştu',
            es: 'Se produjo un error',
            ar: 'حدث خطأ',
            de: 'Ein Fehler ist aufgetreten',
            pt: 'Ocorreu um erro',
            fr: "Une erreur s'est produite",
            zh: '出现错误',
            ko: '오류가 발생했습니다',
            ja: 'エラーが発生しました。',
            th: 'เกิดข้อผิดพลาด',
            he: 'אירעה שגיאה',
            zh_tw: '发生错误',
            fil: 'May naganap na error'
        },
        core_7: {
            ru: 'Перезагрузить страницу',
            en: 'Reload page',
            tr: 'Sayfayı yenile',
            es: 'Recargar página',
            ar: 'أعد تحميل الصفحة',
            de: 'Seite neu laden',
            pt: 'Recarregar página',
            fr: 'Recharger la page',
            zh: '重新加载页面',
            ko: '페이지 새로 고침',
            ja: 'ページをリロードしてください。',
            th: 'โหลดหน้าใหม่',
            he: 'טען מחדש את הדף',
            zh_tw: '重新加载页面',
            fil: 'I-refresh ang pahina'
        },
        core_8: {
            ru: 'В поле используются спецсимволы или дефис исльзуется не по назначению',
            en: 'Special characters or hyphens are not used correctly in the field',
            tr: 'Özel karakterler veya tireler alanında doğru kullanılmamış',
            es: 'Los caracteres especiales o guiones no se usan correctamente en el campo',
            ar: 'الأحرف الخاصة أو الواصلات ليست مستخدمة بشكل صحيح في الحقل',
            de: 'Sonderzeichen oder Bindestriche werden im Feld nicht korrekt verwendet',
            pt: 'Caracteres especiais ou hífens não são usados corretamente no campo',
            fr: 'Les caractères spéciaux ou les tirets ne sont pas utilisés correctement dans le champ',
            zh: '字段中使用了特殊字符或破折号用途不正常。',
            ko: '특수 문자 또는 하이픈이 필드에 올바르게 사용되지 않았습니다',
            ja: 'フィールドに特殊文字やハイフンが正しく使用されていません。',
            th: 'ตัวอักษรพิเศษหรือขีดกลางไม่ถูกใช้งานให้ถูกต้องในช่องข้อมูล',
            he: 'התווים המיוחדים או המקפים לא הופעלו כראוי בשדה',
            zh_tw: '字段中的特殊字符或连字符使用不正确',
            fil: 'Ang mga espesyal na karakter o gitling ay hindi tamang ginamit sa patlang'
        },
        core_9: {
            ru: 'Ошибка при копировании в буфер',
            en: 'Error copying to clipboard',
            tr: 'Panoya kopyalama hatası',
            es: 'Error al copiar al portapapeles',
            ar: 'خطأ في النسخ إلى الحافظة',
            de: 'Fehler beim Kopieren in die Zwischenablage',
            pt: 'Erro ao copiar para a área de transferência',
            fr: 'Erreur de copie dans le presse-papiers',
            zh: '复制到剪贴板时出错。',
            ko: '클립보드로 복사하는 중 오류가 발생했습니다',
            ja: 'クリップボードへのコピー中にエラーが発生しました。',
            th: 'การคัดลอกไปยังคลิปบอร์ดมีข้อผิดพลาด',
            he: 'שגיאה בהעתקה ללוח',
            zh_tw: '复制到剪贴板时出错',
            fil: 'May error sa pagkopya sa clipboard'
        },
        core_10: {
            ru: 'Лицензия с такой почтой уже существует',
            en: 'A license with this email already exists',
            tr: 'Bu e-posta ile bir lisans zaten var',
            es: 'Ya existe una licencia con este correo electrónico',
            ar: 'توجد بالفعل رخصة بهذا البريد الإلكتروني',
            de: 'Eine Lizenz mit dieser E-Mail existiert bereits',
            pt: 'Já existe uma licença com este email',
            fr: 'Une licence avec cet email existe déjà',
            zh: '具有相同电子邮件的许可证已存在。',
            ko: '이 이메일로 라이선스가 이미 존재합니다',
            ja: 'このメールアドレスでのライセンスは既に存在します。',
            th: 'ใบอนุญาตด้วยอีเมลนี้มีอยู่แล้ว',
            he: 'רישיון עם כתובת האימייל הזו כבר קיים',
            zh_tw: '此电子邮件的许可证已存在',
            fil: 'Mayroon nang lisensya na may email na ito'
        },
        core_11: {
            ru: 'В поле "Почта" допущена ошибка',
            en: 'There is an error in the "Email" field',
            tr: 'Email alanında hata var',
            es: 'Hay un error en el campo "Correo electrónico"',
            ar: 'هناك خطأ في حقل "البريد الإلكتروني"',
            de: 'Es liegt ein Fehler im Feld "E-Mail" vor',
            pt: 'Há um erro no campo "Email"',
            fr: 'Il y a une erreur dans le champ "Email"',
            zh: '“邮件”字段存在错误。',
            ko: '이메일 필드에 오류가 있습니다',
            ja: '「メール」フィールドにエラーがあります。',
            th: 'มีข้อผิดพลาดในฟิลด์ "อีเมล"',
            he: 'יש שגיאה בשדה "אימייל"',
            zh_tw: '电子邮件字段中有错误',
            fil: 'May error sa patlang ng "Email"'
        },
        core_12: {
            ru: 'Невалидные данные в строках:',
            en: 'Invalid data in the following lines:',
            tr: 'Aşağıdaki satırlarda geçersiz veri:',
            es: 'Datos inválidos en las siguientes líneas:',
            ar: 'بيانات غير صالحة في الخطوط التالية:',
            de: 'Ungültige Daten in den folgenden Zeilen:',
            pt: 'Dados inválidos nas seguintes linhas:',
            fr: 'Données invalides dans les lignes suivantes :',
            zh: '无效数据行：',
            ko: '다음 라인에 잘못된 데이터가 있습니다:',
            ja: '次の行に無効なデータがあります：',
            th: 'ข้อมูลไม่ถูกต้องในบรรทัดต่อไปนี้:',
            he: 'נתונים שגויים בשורות הבאות:',
            zh_tw: '以下行中的数据无效：',
            fil: 'Maling data sa sumusunod na mga linya:'
        },
        core_13: {
            ru: 'Недостаточное кол-во свободных лицензий',
            en: 'Insufficient number of free licenses',
            tr: 'Yeterli sayıda boş lisans yok',
            es: 'Número insuficiente de licencias libres',
            ar: 'عدد غير كاف من التراخيص الحرة',
            de: 'Unzureichende Anzahl von freien Lizenzen',
            pt: 'Número insuficiente de licenças gratuitas',
            fr: 'Nombre insuffisant de licences gratuites',
            zh: '没有足够的空闲许可证。',
            ko: '사용 가능한 라이선스가 충분하지 않습니다',
            ja: '空きライセンスの数が不十分です。',
            th: 'จำนวนใบอนุญาตที่ว่างไม่เพียงพอ',
            he: 'אין מספיק רישיונות פנויים',
            zh_tw: '免费许可证数量不足',
            fil: 'Kulang ang bilang ng mga libreng lisensya'
        },
        core_14: {
            ru: 'Нет верифицированных кейсов или кейсы отсутствуют',
            en: 'No verified cases or cases are missing',
            tr: 'Doğrulanmış vakalar yok veya vakalar eksik',
            es: 'No hay casos verificados o faltan casos',
            ar: 'لا توجد حالات مؤكدة أو الحالات مفقودة',
            de: 'Keine bestätigten Fälle oder Fälle fehlen',
            pt: 'Não há casos verificados ou os casos estão faltando',
            fr: 'Pas de cas vérifiés ou de cas manquants',
            zh: '没有经过验证的案例或案例不存在。',
            ko: '확인된 사례가 없거나 사례가 누락되었습니다',
            ja: '確認済みのケースがないか、ケースが不足しています。',
            th: 'ไม่มีเคสที่ตรวจสอบหรือเคสขาดหายไป',
            he: 'אין מקרים שאומתו או שהמקרים חסרים',
            zh_tw: '没有经过验证的案例或缺少案例',
            fil: 'Walang naverify na mga kaso o nawawalang mga kaso'
        },
        core_15: {
            ru: 'VR устройство с таким ID уже привязано',
            en: 'A VR device with this ID is already bound',
            tr: 'Bu kimlikle bir VR cihazı zaten bağlı',
            es: 'Ya existe un dispositivo de realidad virtual con esta ID',
            ar: 'هناك جهاز واقع افتراضي بنفس هذا المعرف مرتبط بالفعل',
            de: 'Ein VR-Gerät mit dieser ID ist bereits gebunden',
            pt: 'Já existe um dispositivo de RV com este ID vinculado',
            fr: 'Un appareil de réalité virtuelle avec cet identifiant est déjà lié',
            zh: '具有此ID的VR设备已经绑定。',
            ko: '이 ID를 가진 VR 장치는 이미 바인딩되어 있습니다',
            ja: 'このIDのVRデバイスはすでにバインドされています。',
            th: 'มีอุปกรณ์ VR ด้วย ID นี้ที่ถูกผูกไว้แล้ว',
            he: 'ישנו מכשיר VR עם מזהה זהה שכבר מחובר',
            zh_tw: '此ID的VR设备已经绑定',
            fil: 'Mayroon nang VR device na may ID na ito na nakatali'
        },
        core_16: {
            ru: 'Срок создания лицензий закончился',
            en: 'The license creation period has ended',
            tr: 'Lisans oluşturma süresi sona erdi',
            es: 'El período de creación de licencias ha finalizado',
            ar: 'انتهت فترة إنشاء التراخيص',
            de: 'Die Lizenzierungsfrist ist abgelaufen',
            pt: 'O período de criação de licenças terminou',
            fr: 'La période de création de licences est terminée',
            zh: '许可证创建期已结束。',
            ko: '라이선스 생성 기간이 종료되었습니다',
            ja: 'ライセンス作成期間が終了しました。',
            th: 'ระยะเวลาการสร้างใบอนุญาตสิ้นสุดลง',
            he: 'תקופת יצירת הרישיון הסתיימה',
            zh_tw: '许可证创建期已结束',
            fil: 'Tapos na ang panahon ng paglikha ng lisensya'
        },
        core_17: {
            ru: 'Дата не может быть раньше текущей',
            en: 'The date cannot be earlier than the current date',
            tr: 'Tarih, mevcut tarihten önce olamaz',
            es: 'La fecha no puede ser anterior a la fecha actual',
            ar: 'لا يمكن أن تكون التاريخ قبل التاريخ الحالي',
            de: 'Das Datum kann nicht vor dem aktuellen Datum liegen',
            pt: 'A data não pode ser anterior à data atual',
            fr: 'La date ne peut pas être antérieure à la date actuelle',
            zh: '日期不能早于当前日期。',
            ko: '날짜는 현재 날짜보다 이전일 수 없습니다',
            ja: '日付は現在の日付より前にすることはできません。',
            th: 'วันที่ไม่สามารถเป็นก่อนวันที่ปัจจุบันได้',
            he: 'התאריך לא יכול להיות מוקדם מהתאריך הנוכחי',
            zh_tw: '日期不能早于当前日期',
            fil: 'Hindi maaaring mas maaga ang petsa kaysa sa kasalukuyang petsa'
        },
        core_18: {
            ru: 'Дата лимита активации не может быть позже даты окончания действия пакета',
            en: 'The activation limit date cannot be later than the package expiration date',
            tr: 'Aktivasyon sınır tarihi, paket son kullanma tarihinden sonra olamaz',
            es: 'La fecha límite de activación no puede ser posterior a la fecha de vencimiento del paquete',
            ar: 'لا يمكن أن يكون تاريخ حد النشاط بعد تاريخ انتهاء صلاحية الحزمة',
            de: 'Das Aktivierungslimit-Datum kann nicht nach dem Ablaufdatum des Pakets liegen',
            pt: 'A data limite de ativação não pode ser posterior à data de expiração do pacote',
            fr: "La date limite d'activation ne peut pas être postérieure à la date d'expiration du forfait",
            zh: '激活限制日期不能晚于套餐到期日期。',
            ko: '활성화 제한 날짜는 패키지 만료일보다 이후일 수 없습니다',
            ja: '有効期限日はパッケージの終了日より後にすることはできません。',
            th: 'วันที่จำกัดการเปิดใช้งานไม่สามารถเป็นหลังวันที่หมดอายุของแพคเกจได้',
            he: 'תאריך ההפעלה לא יכול להיות מאוחר מתאריך תפוגת החבילה',
            zh_tw: '激活限制日期不能晚于包过期日期',
            fil: 'Hindi maaaring mas huli ang petsa ng limitasyon ng pag-activate kaysa sa petsa ng pag-expire ng package'
        },
        auth_1: {
            ru: 'Возникли проблемы при подключение, попробуйте позже',
            en: 'There were problems connecting, please try again later',
            tr: 'Bağlantıda sorunlar oldu, lütfen daha sonra tekrar deneyin',
            es: 'Hubo problemas de conexión, por favor intente de nuevo más tarde',
            ar: 'كانت هناك مشكلات في الاتصال ، يرجى المحاولة مرة أخرى في وقت لاحق',
            de: 'Es gab Verbindungsprobleme, bitte versuchen Sie es später erneut',
            pt: 'Houve problemas na conexão, por favor, tente novamente mais tarde',
            fr: 'Il y a eu des problèmes de connexion, veuillez réessayer plus tard',
            zh: '连接时出现问题，请稍后再试。',
            ko: '연결하는 동안 문제가 발생했습니다 나중에 다시 시도하세요',
            ja: '接続に問題が発生しました。後でもう一度お試しください。',
            th: 'เกิดปัญหาในการเชื่อมต่อ โปรดลองอีกครั้งในภายหลัง',
            he: 'היו בעיות בהתחברות, אנא נסה שוב מאוחר יותר',
            zh_tw: '连接时出现问题，请稍后再试',
            fil: 'May mga problema sa pagkonekta, subukan muli mamaya'
        },
        auth_2: {
            server: 'User not found.',
            ru: 'Пользователь не найден',
            en: 'User not found',
            tr: 'Kullanıcı bulunamadı',
            es: 'Usuario no encontrado',
            ar: 'لم يتم العثور على المستخدم',
            de: 'Benutzer nicht gefunden',
            pt: 'Usuário não encontrado',
            fr: 'Utilisateur non trouvé',
            zh: '未找到用户。',
            ko: '사용자를 찾을 수 없습니다',
            ja: 'ユーザーが見つかりません。',
            th: 'ไม่พบผู้ใช้',
            he: 'המשתמש לא נמצא',
            zh_tw: '找不到用户',
            fil: 'Hindi natagpuan ang user'
        },
        auth_3: {
            server: 'Wrong password.',
            ru: 'Указан неверный пароль',
            en: 'Incorrect password entered',
            tr: 'Yanlış şifre girildi',
            es: 'Contraseña incorrecta ingresada',
            ar: 'تم إدخال كلمة مرور غير صحيحة',
            de: 'Falsches Passwort eingegeben',
            pt: 'Senha incorreta inserida',
            fr: 'Mot de passe incorrect entré',
            zh: '密码不正确。',
            ko: '잘못된 비밀번호가 입력되었습니다',
            ja: '入力されたパスワードが正しくありません。',
            th: 'ป้อนรหัสผ่านไม่ถูกต้อง',
            he: 'הסיסמה שהוזנה איננה נכונה',
            zh_tw: '输入的密码不正确',
            fil: 'Maling password na ipinasok'
        },
        auth_5: {
            ru: 'Ошибка Авторизации',
            en: 'Authorization error',
            tr: 'Yetkilendirme hatası',
            es: 'Error de autorización',
            ar: 'خطأ في التفويض',
            de: 'Autorisierungsfehler',
            pt: 'Erro de autorização',
            fr: "Erreur d'autorisation",
            zh: '授权错误。',
            ko: '인증 오류',
            ja: '認証エラー。',
            th: 'ข้อผิดพลาดในการอนุญาต',
            he: 'שגיאת אימות',
            zh_tw: '授权错误',
            fil: 'Error sa awtorisasyon'
        },
        auth_6: {
            server: 'PackId empty',
            ru: 'Пользователю не присвоен профиль',
            en: 'User has no profile assigned',
            tr: 'Kullanıcının atanan bir profili yok',
            es: 'El usuario no tiene un perfil asignado',
            ar: 'المستخدم ليس لديه ملف شخصي معين',
            de: 'Dem Benutzer ist kein Profil zugeordnet',
            pt: 'Usuário não possui um perfil atribuído',
            fr: "L'utilisateur n'a pas de profil attribué",
            zh: '用户未分配个人资料。',
            ko: '사용자에게 할당된 프로필이 없습니다',
            ja: 'ユーザーにはプロファイルが割り当てられていません。',
            th: 'ผู้ใช้ไม่ได้รับการกำหนดโปรไฟล์',
            he: 'למשתמש אין פרופיל שהוקצה לו',
            zh_tw: '用户没有分配的配置文件',
            fil: 'Walang profile na inilaan sa user'
        },
        auth_7: {
            server: "You don't have permission to access this resource.",
            ru: 'У вас нет разрешения на доступ к этому ресурсу',
            en: 'You do not have permission to access this resource',
            tr: 'Bu kaynağa erişim hakkınız yok',
            es: 'No tienes permiso para acceder a este recurso',
            ar: 'ليس لديك الإذن للوصول إلى هذا المورد',
            de: 'Sie haben keine Berechtigung zum Zugriff auf diese Ressource',
            pt: 'Você não tem permissão para acessar este recurso',
            fr: "Vous n'avez pas la permission d'accéder à cette ressource",
            zh: '您没有权限访问此资源。',
            ko: '이 리소스에 액세스할 권한이 없습니다',
            ja: 'このリソースにアクセスする権限がありません。',
            th: 'คุณไม่มีสิทธิ์เข้าถึงทรัพยากรนี้',
            he: 'אין לך הרשאה לגשת למשאב זה',
            zh_tw: '您没有访问此资源的权限',
            fil: 'Wala kang pahintulot na ma-access ang mapagkukunan na ito'
        },
        auth_8: {
            ru: 'Неверно указана почта',
            en: 'Incorrect email address',
            tr: 'Hatalı e-posta adresi',
            es: 'Dirección de correo electrónico incorrecta',
            ar: 'عنوان البريد الإلكتروني غير صحيح',
            de: 'Falsche E-Mail-Adresse',
            pt: 'Endereço de e-mail incorreto',
            fr: 'Adresse e-mail incorrecte',
            zh: '电子邮件地址不正确',
            ko: '잘못된 이메일 주소입니다',
            ja: 'メールアドレスが正しくありません',
            th: 'ที่อยู่อีเมลไม่ถูกต้อง',
            he: 'כתובת האימייל איננה תקינה',
            zh_tw: '电子邮件地址不正确。',
            fil: 'Maling email address'
        }
    }
}