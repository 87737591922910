import {makeAutoObservable} from 'mobx';
import {
    IParamsLogAutocomplete,
    ISelectedSearchLogs,
    LogAttr,
    searchInterface,
    searchVisibleInterface
} from '../interface';

export default class LogStore {
    //Флаги отображения филтров по категориям
    searchVisible: searchVisibleInterface = {
        category: false,
        user: false,
        date: false,
        std: false,
        curCase: false,
        pack: false,
        prevValue: false,
        newValue: false,
        lang: false,
        info: false
    };
    //Значения поиска по категориям
    search: searchInterface = {
        user: '',
        date: '',
        curCase: '',
        pack: '',
        prevValue: '',
        newValue: '',
        info: ''
    };
    //Данные логов
    logs: Array<LogAttr>;
    //категории, которые показывать в логировании
    selectedCheckboxSearchLogs: ISelectedSearchLogs = {
        category: [],
        std: [],
        lang: [],
        verify: []
    };
    //Параметры поиска в логах
    searchLogs: searchInterface = {
        user: '',
        date: '',
        curCase: '',
        pack: '',
        prevValue: '',
        newValue: '',
        info: ''
    };
    //Выбраные checkbox для отображения логов
    selectedCheckboxSearch: ISelectedSearchLogs = {
        category: [],
        std: [],
        lang: [],
        verify: []
    };
    //Видимость записей для определенного пользователя
    onlyUser: string
    //обновление данных
    refresh: boolean = false;
    //ресет лимита данных
    resetLimit: boolean = false;
    //автокомлит для логов
    logAutocomplete: string[] = [];
    paramsLogAutocomplete: IParamsLogAutocomplete = {
        show: false,
        width: '',
        x: '',
        y: '',
        type: '',
        value: '',
        above: false
    };
    //флаг установки фильтра по клику на ячейку
    clickCell: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    updateClickCell() {
        this.clickCell = !this.clickCell;
    }

    setLogAutocomplete(data: string[]) {
        this.logAutocomplete = data;
    }

    setParamsLogAutocomplete(data: IParamsLogAutocomplete) {
        this.paramsLogAutocomplete = data;
    }

    setParamsLogAutocompleteValue(data: string) {
        this.paramsLogAutocomplete.value = data;
    }

    resetParamsLogAutocomplete() {
        this.logAutocomplete = [];
        this.paramsLogAutocomplete = {
            show: false,
            width: '',
            x: '',
            y: '',
            type: '',
            value: '',
            above: false
        };
    }

    needRefresh() {
        this.refresh = !this.refresh;
    }

    //Изменение флага филтьтра
    changeSearchVisible(key: string) {
        this.searchVisible[key] = !this.searchVisible[key];
    }

    //Изменение поиска
    changeSearch(key: string, value: string) {
        this.search[key] = value;
    }

    //Установка данных по логам
    setLogs(data: Array<LogAttr>) {
        this.logs = data;
    }

    //Установка определеного пользователя
    setOnlyUser(data: string) {
        this.onlyUser = data;
    }

    //Установка параметров логов
    setParamsLogs(checkboxObj: ISelectedSearchLogs, searchObj: searchInterface) {
        this.selectedCheckboxSearchLogs = {...checkboxObj};
        this.searchLogs = {...searchObj};
        this.resetLimit = !this.resetLimit;
    }

    //обновление выбранной категории
    updateSelectedCategory(type: string, data: string) {
        this.selectedCheckboxSearch[type] = [...this.selectedCheckboxSearch[type], data];
    }

    //удаление выбранной категории
    spliceSelectedCategory(type: string, data: string) {
        this.selectedCheckboxSearch[type].splice(this.selectedCheckboxSearch[type].indexOf(data), 1);
    }

    setSelectedCategory(type: string, data: Array<string>) {
        this.selectedCheckboxSearch[type] = data;
    }

    //Сброс всех параметров
    resetAll() {
        this.searchVisible = {
            category: false,
            user: false,
            date: false,
            std: false,
            curCase: false,
            pack: false,
            prevValue: false,
            newValue: false,
            lang: false,
            info: false
        };
        this.search = {
            user: '',
            date: '',
            curCase: '',
            pack: '',
            prevValue: '',
            newValue: '',
            info: ''
        };
        this.searchLogs = {
            user: '',
            date: '',
            curCase: '',
            pack: '',
            prevValue: '',
            newValue: '',
            info: ''
        };
        this.selectedCheckboxSearch = {
            category: [],
            std: [],
            lang: [],
            verify: []
        };
        this.selectedCheckboxSearchLogs = {
            category: [],
            std: [],
            lang: [],
            verify: []
        };
        this.resetLimit = !this.resetLimit;
    }
}
